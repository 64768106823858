import { getToken } from "./auth";

export const ENDPOINTS = {
  SELL_REQUESTS: "/internal/sell-requests",
  BASIC_ITEMS: "/basics",
};

export function makeHeader() {
  return { authorization: `bearer ${getToken()}` };
}
