import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";
import { userReportT } from "./types";
import { ReportTable } from "./reportTable";

export const UserReport: FC<unknown> = () => {
  const { data } = useGetQueryQuery(
    `internal/sell-requests/report/daily/users`
  );

  const userReportDataList: userReportT = data;

  if (!userReportDataList) {
    return <p>...Loading</p>;
  }

  return (
    <Box
      sx={{
        py: 6,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 8,
        alignItems: "center",
      }}
    >
      <h2 style={{ fontWeight: "bold" }}>METACAR USER SIGNUP REPORT</h2>

      <ReportTable type="userReport" data={userReportDataList} />
    </Box>
  );
};
