import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FC, useState } from "react";
import { SellRequestReport } from "./sellRequestReport";
import { UserReport } from "./userReport";
import { AverageReport } from "./averageReport";

export const Report: FC<unknown> = () => {
  const [value, setValue] = useState<string>("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{ style: { height: 4 } }}
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              position: "fixed",
              width: "100%",
              zIndex: 1,
              backgroundColor: "#424242",
            }}
          >
            <Tab
              label="Sell Request Report"
              value="1"
              sx={{ color: "#fff !important" }}
            />
            <Tab
              label="User Report"
              value="2"
              sx={{ color: "#fff !important" }}
            />
            <Tab
              label="Average Car Price"
              value="3"
              sx={{ color: "#fff !important" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SellRequestReport />
        </TabPanel>
        <TabPanel value="2">
          <UserReport />
        </TabPanel>
        <TabPanel value="3">
          <AverageReport />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
