import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

import { Control, Controller, FieldValues } from "react-hook-form";

interface Props {
  name: string;
  id: string;
  label: string;
  control: Control<any, any>;
  defaultValue: any;
  children: ReactNode;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | ReactNode;
  readOnly?: boolean;
  showHelperText?: boolean;
}

export const SelectFormHook: FC<Props> = ({
  name,
  label,
  control,
  id,
  defaultValue,
  children,
  disabled,
  required,
  helperText,
  readOnly,
  showHelperText,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props} sx={{ width: "100%" }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        disabled={disabled}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={disabled}
            sx={{ textAlign: "left" }}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            label="Campaign Budget"
            labelId="campaign_budget_label"
            required={required}
          >
            {children}
          </Select>
        )}
      />
      {showHelperText ? <Typography>{helperText}</Typography> : null}
    </FormControl>
  );
};
