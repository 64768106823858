import React, { useEffect, useState } from "react";
import { Card } from "../../shared/card";
import "./styles.scss";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface Inputs {
  remark: string;
  appointments: {
    type: string;
    times: [
      {
        timestamp: string;
        duration: number;
      }
    ];
  };
}
interface Props {
  requestId: number;
  sellRequestData: sellRequestDataT;
  refetch: () => void;
}

export const InspectionDetails: React.FC<Props> = ({
  sellRequestData,
  refetch,
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onNextHandler = () => {
    refetch();
    navigate(
      `/inspection-report/${sellRequestData.id}?report_id=${sellRequestData.inspections[0]?.id}`
    );
  };

  useEffect(() => {
    if (
      sellRequestData?.inspections?.[0] &&
      sellRequestData?.inspections?.[0]?.docuemnt?.status === "generating"
    ) {
      setIsLoading(true);
      const intervalCall = setInterval(() => {
        refetch();
      }, 8000);
      return () => {
        // clean up
        clearInterval(intervalCall);
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [sellRequestData?.inspections?.[0]]);

  return (
    <div className="seller-detail-container">
      <Card header="Inspection Report">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {sellRequestData &&
          sellRequestData?.inspections?.[0]?.docuemnt &&
          sellRequestData?.inspections?.[0]?.docuemnt.url ? (
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} gap={2}>
                <Typography>Inspection Report Date:</Typography>
                <Typography fontWeight={"bold"}>
                  {dayjs(
                    sellRequestData?.inspections?.[0]?.docuemnt?.generatedDate
                  ).format("DD MMM YY")}
                </Typography>
              </Box>
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Typography>Inspection Report Doc:</Typography>

                <IconButton
                  onClick={() =>
                    sellRequestData?.inspections?.[0]?.docuemnt?.url &&
                    window.open(
                      sellRequestData?.inspections?.[0]?.docuemnt?.url,
                      "_blank",
                      "fullscreen=yes"
                    )
                  }
                >
                  <PictureAsPdf color="warning" />
                </IconButton>
              </Box>
            </Box>
          ) : sellRequestData?.inspections?.[0]?.docuemnt?.status ===
            "generating" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={100} sx={{ color: "#F5903D" }} />{" "}
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Typography>No Inspection Report Yet!</Typography>{" "}
            </Box>
          )}

          <Box>
            <Button
              disabled={!sellRequestData.appointment.timestamp}
              variant="contained"
              sx={{ m: 4, mt: 0, width: 240, float: "right" }}
              onClick={onNextHandler}
            >
              Inspection details
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
};
