import { Snackbar, Box, Typography, Alert } from "@mui/material";
import { useState, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../utility/redux/state/features/auth.slicer";

interface Props {
  isOpen: boolean;
  title: string;
  description?: string;
  type: "error" | "warning" | "info" | "success";
  closeHandler?: () => void;
}

export const Toaster: FC<Props> = ({ isOpen, title, description, type }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);

    dispatch(
      setToaster({
        isOpen: false,
        title: "",
      })
    );
  };

  const message = (
    <Box>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="subtitle2">{description}</Typography>
    </Box>
  );

  return (
    <Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
