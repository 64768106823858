import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card } from "../../components/shared/card";
import { TextBox } from "../../components/shared/TextBox";
import { Button } from "../../components/shared/button";
import "./login.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { postLogin } from "../../../utility/redux/state/features/actions/auth";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/General/loading/loading";
import { Box, responsiveFontSizes } from "@mui/material";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.authReducer.value.isAuth);
  const loading = useAppSelector((state) => state.authReducer.loading);

  const onLoginClicked = (data: any) => {
    dispatch(postLogin(data));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn]);
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "ButtonFace",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Box
          component={"img"}
          src="https://metacar.my/images/metacar-logo.png"
          className="logo"
          sx={{ objectFit: "cover", maxWidth: 380 }}
        />
        <Card header="Sign In" minWidth="600px">
          <form onSubmit={handleSubmit(onLoginClicked)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextBox
                bigFont
                fullWidth
                label="User Name"
                required
                register={{ ...register("username") }}
              />
              <TextBox
                bigFont
                fullWidth
                label="Password"
                required
                register={{ ...register("password") }}
                type="password"
              />
              <hr />
              {loading && <Loading />}{" "}
              <Button
                color="info"
                variant="contained"
                minHeight={60}
                label="Login"
              />
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};
export default LoginPage;
