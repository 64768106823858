import "./styles.scss";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card } from "../../shared/card";
import "./styles.scss";
import {
  useGetQueryQuery,
  usePatchRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { Box, MenuItem, TextField } from "@mui/material";
import { RULES } from "../car-details/rules";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
interface Inputs {
  address: {
    addressType: string;
    country: string;
    stateId: { id: number; title: string };
    city: string;
    postalCode: string;
    addressLine: string;
  };
  sellerInfo: {
    phoneNumber: string;
    email: string;
    name: string;
  };
}
interface sellerDetailsProps {
  requestId: number;
  sellRequestData: sellRequestDataT;
}

const SellerDetails: React.FC<sellerDetailsProps> = ({
  requestId,
  sellRequestData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const [patchData, result] = usePatchRequestMutation();

  const { data: countryBasics } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsLocations}`
  );

  const { data: stateBasics } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsLocations}/malaysia`
  );

  const onSubmit = async (data: any) => {
    const payload = {
      requestId: requestId,
      address: {
        type: "HOME",
        stateId: data.address.stateId.id,
        city: data.address.city,
        postalCode: data.address.postalCode,
        addressLine: data.address.addressLine,
      },
      sellerInfo: {
        phoneNumber: data.sellerInfo.phoneNumber,
        email: data.sellerInfo.email,
        name: data.sellerInfo.name,
      },
    };
    const result = await patchData({
      url: "internal/sell-requests/operations/seller-info",
      payload,
    });

    if ("data" in result) {
      result.data &&
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully updated",
            type: "success",
          })
        );
    }

    if ("error" in result) {
      result.error &&
        dispatch(
          setToaster({
            isOpen: true,
            title: "Something went wrong",
            type: "error",
          })
        );
    }
  };

  useEffect(() => {
    const addressLine = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].address
      : undefined;
    const city = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].city
      : undefined;
    const addressType = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].type
      : "HOME";
    const state = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].state
      : undefined;
    const postalCode = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].postalCode
      : undefined;

    reset({
      address: {
        addressLine,
        city,
        addressType,
        stateId: stateBasics?.list?.filter(
          (item: { id: number; title: string }) => {
            return item.title === state;
          }
        )[0],
        postalCode,
      },

      sellerInfo: {
        phoneNumber: sellRequestData.seller.phone,
        email: sellRequestData.seller.email,
        name: sellRequestData.seller.name,
      },
    });
  }, [reset, countryBasics, stateBasics]);

  return (
    <div className="seller-detail-container">
      <Card header="Seller Detail">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <Box display={"flex"} justifyContent={"space-between"} gap={6}>
            <Box display={"flex"} flexDirection={"column"} gap={6} flexGrow={2}>
              <Box display={"flex"} gap={2}>
                <TextField
                  {...register("sellerInfo.phoneNumber")}
                  margin="normal"
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  autoComplete="phoneNumber"
                  autoFocus
                  error={!!errors.sellerInfo?.phoneNumber?.message}
                  helperText={errors.sellerInfo?.phoneNumber?.message}
                  sx={{ m: 0 }}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  {...register("sellerInfo.name")}
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  autoComplete="name"
                  autoFocus
                  error={!!errors.sellerInfo?.name?.message}
                  helperText={errors.sellerInfo?.name?.message}
                  sx={{ m: 0 }}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  {...register("sellerInfo.email", RULES.EMAIL)}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  autoComplete="email"
                  autoFocus
                  error={!!errors.sellerInfo?.email?.message}
                  helperText={errors.sellerInfo?.email?.message}
                  sx={{ m: 0 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} gap={2}>
                {/* <SelectFormHook
                  id="address.addressType"
                  name="address.addressType"
                  label="Address Type"
                  control={control}
                  defaultValue={""}
                >
                  {["WORK", "HOME"].map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </SelectFormHook> */}

                <TextField
                  {...register("address.city")}
                  margin="normal"
                  fullWidth
                  id="city"
                  label="City"
                  autoComplete="city"
                  autoFocus
                  error={!!errors.address?.city?.message}
                  helperText={errors.address?.city?.message}
                  sx={{ m: 0 }}
                  InputLabelProps={{ shrink: true }}
                />

                <AutoCompleteFormHook
                  options={stateBasics?.list}
                  disabled={false}
                  name="address.stateId"
                  label="State"
                  control={control}
                  defaultValue={""}
                />

                <TextField
                  {...register("address.postalCode")}
                  margin="normal"
                  fullWidth
                  id="postalCode"
                  label="PostalCode"
                  autoComplete="postalCode"
                  autoFocus
                  error={!!errors.address?.postalCode?.message}
                  helperText={errors.address?.postalCode?.message}
                  sx={{ m: 0 }}
                />
              </Box>
            </Box>
            <Box display={"flex"} flexGrow={2}>
              <TextField
                {...register("address.addressLine")}
                margin="normal"
                fullWidth
                id="addressLine"
                label="Address Line"
                autoComplete="addressLine"
                autoFocus
                error={!!errors.address?.addressLine?.message}
                helperText={errors.address?.addressLine?.message}
                multiline
                rows={6}
              />
            </Box>
          </Box>

          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};
export default SellerDetails;
