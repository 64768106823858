import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchSellRequestApi,
  fetchSellRequestByIdApi,
} from "./actions/sell-requests";
import { fetchBasicItems } from "./actions/basic-items";
import { ISellRequest } from "../../../../core/types/sell-request";
import { IBasicItemsResponse } from "../../../../core/types/basic-items";

type InitialState = {
  count?: number;
  basicItems?: IBasicItemsResponse;
  loading: boolean;
};

type AuthState = {
  isAuth: boolean;
  username?: string;
  token?: string;
};

const initialState: InitialState = {
  loading: false,
};

export const auth = createSlice({
  name: "basic-items",
  initialState,
  reducers: {
    fillData: (
      state,
      dto: PayloadAction<{
        status: number;
        username: string;
        access: {
          token: string;
          expireIn: number;
        };
      }>
    ) => {
      return {
        ...state,
        value: {
          isAuth: dto.payload != null,
          token: dto.payload.access?.token,
          username: dto.payload.username,
        },
      };
    },
    logOut: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSellRequestApi.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchBasicItems.fulfilled, (state, action) => {
      state.basicItems = action.payload;
    });
  },
});

export const { fillData } = auth.actions;

export default auth.reducer;
