import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_API;
export const postLogin = createAsyncThunk(
  "POST /login",
  async (payload: any, thunkAPI) => {
    const response = axios.post(baseURL + "/internal/staff/login", payload);
    return (await response).data;
  }
);

export function getToken() {
  return window.localStorage.getItem("token");
}
