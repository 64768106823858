import { Button as ButtonComponent } from "@mui/material";

export function Button({
  label,
  icon,
  variant = "outlined",
  type = "submit",
  onClick,
  disabled = false,
  iconPosition = "left",
  color = "inherit",
  minHeight,
}: {
  label: string;
  icon?: any;
  variant?: "text" | "outlined" | "contained";
  type?: "submit" | "button";
  onClick?: () => void;
  disabled?: boolean;
  iconPosition?: "left" | "right";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  minHeight?: number;
}) {
  return (
    <ButtonComponent
      type={type}
      disabled={disabled}
      sx={{ minWidth: 100, minHeight: minHeight ? minHeight : "initial" }}
      variant={variant}
      onClick={onClick}
      startIcon={iconPosition == "left" && icon}
      endIcon={iconPosition == "right" && icon}
      color={color}
    >
      {label}
    </ButtonComponent>
  );
}
