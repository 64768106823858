import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";
import { ReportT } from "./types";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { ReportTable } from "./reportTable";

export const SellRequestReport: FC<unknown> = () => {
  const [reportDataList, setReportDataList] = useState<
    {
      id: number;
      value: number;
      label: string;
    }[]
  >();

  const { data: list } = useGetQueryQuery(
    `internal/sell-requests/report/brand-status`
  );

  const reportData: ReportT = list;

  useEffect(() => {
    if (!reportData) {
      return;
    }
    const bookedCar = reportData?.data.filter(
      (item) => item.status === "booked"
    );
    const countBookedCar = bookedCar.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const publishedCar = reportData?.data.filter(
      (item) => item.status === "published"
    );

    const countPublishedCar = publishedCar.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const pendingCar = reportData?.data.filter(
      (item) => item.status === "pending"
    );

    const countPendingCar = pendingCar.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const inspectedCar = reportData?.data.filter(
      (item) => item.status === "inspected"
    );

    const countInspectedCar = inspectedCar.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const totalCount = reportData?.data.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const soldCar = reportData?.data.filter((item) => item.status === "sold");

    const countSoldCar = soldCar.reduce((sum, { count }) => sum + +count, 0);

    const rejectedCar = reportData?.data.filter(
      (item) => item.status === "rejected"
    );

    const countRejectedCar = rejectedCar.reduce(
      (sum, { count }) => sum + +count,
      0
    );

    const currentList = [
      {
        id: 1,
        value: countBookedCar,
        label: "Booked",
        percent: `%${((countBookedCar * 100) / totalCount).toFixed(2)}`,
      },
      {
        id: 2,
        value: countPublishedCar,
        label: "Published",
        color: "#B800D8",
        percent: `%${((countPublishedCar * 100) / totalCount).toFixed(2)}`,
      },
      {
        id: 3,
        value: countPendingCar,
        label: "Pending",
        color: "orange",
        percent: `%${((countPendingCar * 100) / totalCount).toFixed(2)}`,
      },
      {
        id: 4,
        value: countInspectedCar,
        label: "Inspected",
        color: "#b3b300",
        percent: `%${((countInspectedCar * 100) / totalCount).toFixed(2)}`,
      },
      {
        id: 5,
        value: countSoldCar,
        label: "Sold",
        percent: `%${((countSoldCar * 100) / totalCount).toFixed(2)}`,
      },
      {
        id: 6,
        value: countRejectedCar,
        label: "Rejected",
        color: "#e41717",
        percent: `%${((countRejectedCar * 100) / totalCount).toFixed(2)}`,
      },
    ];

    setReportDataList(currentList);
  }, [reportData]);

  if (!reportDataList) {
    return <p>...Loading</p>;
  }

  return (
    <Box
      sx={{
        py: 6,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 8,
        alignItems: "center",
      }}
    >
      <h2 style={{ fontWeight: "bold" }}>METACAR STATUS REPORT</h2>
      <PieChart
        series={[
          {
            data: reportDataList as any,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            arcLabel: (item) => `${item.percent}`,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
        width={800}
        height={580}
      />

      <ReportTable data={reportData} type="sellRequestReport" />
    </Box>
  );
};
