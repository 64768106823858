const PRICE = {
  required: "Please enter car price",
};
const REGISTRATION_NUMBER = {
  required: "Please enter registration number",
  minLength: {
    value: 2,
    message: "At least 6 Characters",
  },
};
const BODY_TYPE = {
  required: "Please select a body type",
};
const COLOR = {
  required: "Please select a color",
};
const GEAR = {
  required: "Please select a transmission",
};
const MODAL = {
  required: "Please select a modal",
};
const BRAND = {
  required: "Please select a brand",
};

const YEAR = {
  required: "Please enter a year",
};

const MONTH = {
  required: "Please enter a month",
};

const TYPE = {
  required: "Please select a address type",
};

const STATE = {
  required: "Please select a state",
};

const CITY = {
  required: "Please enter a city",
};

const POSTAL_CODE = {
  required: "Please enter a postal code",
};

const ADDRESS_LINE = {
  required: "Please enter a address",
};

const COMMON_RULES_STRING = {
  required: "Required Filed",
};

const EMAIL = {
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Invalid Email Address",
  },
};

export const RULES = {
  PRICE,
  REGISTRATION_NUMBER,
  BODY_TYPE,
  COLOR,
  GEAR,
  MODAL,
  BRAND,
  COMMON_RULES_STRING,
  EMAIL,

  YEAR,
  MONTH,
  TYPE,
  STATE,
  CITY,
  POSTAL_CODE,
  ADDRESS_LINE,
};
