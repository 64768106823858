import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { fetchSellRequestApi } from "../../../utility/redux/state/features/actions/sell-requests";
import { Button } from "../../components/shared/button";
import moment from "moment";
import Loading from "../../components/General/loading/loading";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { PageHeader } from "../../components/shared/page-header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RequestFilter } from "../../components/sell-requests/requests-filter";
import { NewSellRequestModal } from "../../components/sell-requests/NewSellRequestModal/NewSellRequestModal";
import { Toaster } from "../../components/General/Toaster";
import { Box, Typography } from "@mui/material";
import { setQueryParams } from "../../../utility/redux/state/features/sell-requests.slicer";
import { cleanObject } from "../../../utility/helpers/objectutily";

const PAGE_SIZE = 10;

export const SellRequestList: React.FC = () => {
  const sellRequest = useAppSelector((state) => state.sellRequestReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPageParams = searchParams?.get("page")
    ? Number(searchParams.get("page"))
    : 0;

  const toaster = useAppSelector((state) => state.authReducer.toaster);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const count = useAppSelector((state) => state.sellRequestReducer.count);
  const loading = useAppSelector((state) => state.sellRequestReducer.loading);
  const [currentPage, setPage] = useState(currentPageParams);

  useEffect(() => {
    setSearchParams(
      `?${new URLSearchParams({
        ...sellRequest.queryParams,
        page: currentPage.toString(),
      })}`
    );
  }, [currentPage, sellRequest.queryParams]);

  useEffect(() => {
    const currentPageParams: number = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0;

    const status = searchParams.get("status");
    const brand = searchParams.get("brand");
    const model = searchParams.get("model");
    const registration_no = searchParams.get("registration_no");
    const start_date = searchParams.get("start_date");
    const end_date = searchParams.get("end_date");
    const client = searchParams.get("client");
    const tracking_code = searchParams.get("tracking_code");

    const queryParamsFilter = {
      status,
      brand,
      model,
      registration_no,
      start_date,
      end_date,
      client,
      tracking_code,
    };

    const currentQueyFilter = cleanObject(queryParamsFilter);

    dispatch(setQueryParams(currentQueyFilter));
    dispatch(
      fetchSellRequestApi({
        skip: currentPageParams * PAGE_SIZE,
        take: PAGE_SIZE,
        ...currentQueyFilter,
      })
    );
  }, []);

  return (
    <Box sx={{ mb: 4 }}>
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <PageHeader label="Sell Requests" />
      <NewSellRequestModal />
      <Box>
        <Box className="filter-panel">
          <RequestFilter
            sellReq
            onChangeFilter={(newFilter) => {
              const currentQueyFilter = cleanObject(newFilter);
              dispatch(setQueryParams(currentQueyFilter));
              setPage(0);
              dispatch(
                fetchSellRequestApi({
                  ...currentQueyFilter,
                  skip: currentPage * PAGE_SIZE,
                  take: PAGE_SIZE,
                })
              );
            }}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          {" "}
          {loading && <Loading />}
          <table className="table table-sm table-hover sellTable">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Make</th>
                <th scope="col">Reg. No.</th>
                <th scope="col">Price(RM)</th>
                <th scope="col">State</th>
                <th scope="col">Year</th>
                <th scope="col">Seller</th>
                <th scope="col" style={{ whiteSpace: "nowrap" }}>
                  Submitted Date
                </th>
                <th scope="col">Shop</th>
                <th scope="col">Grade</th>
                <th scope="col">Status</th>
                <th scope="col" style={{ whiteSpace: "nowrap" }}>
                  Tracking Code
                </th>
                <th scope="col">Image</th>
                <th scope="col">Management</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle" }}>
              {sellRequest.sellRequests?.map((req, index) => {
                return (
                  <tr key={req.id}>
                    <th scope="row">{index + 1 + currentPage * PAGE_SIZE}</th>
                    <td style={{ width: "100px" }}>
                      <Typography variant="caption" sx={{ width: 100 }}>
                        {req.make.brand} {req.make.model} {req.make.variant}
                      </Typography>
                    </td>
                    <td> {req.registrationNo}</td>

                    <td>
                      {req.sellerFinalizedPrice.amount
                        ? (+req.sellerFinalizedPrice.amount).toFixed(2)
                        : 0.0}
                    </td>
                    <td> {req.seller?.address[0]?.state} </td>
                    <td> {moment(req.make.manufactureDate).format("YYYY")} </td>
                    <td>
                      <Typography variant="caption" sx={{ width: 100 }}>
                        {req.seller.name}
                      </Typography>
                    </td>
                    <td> {moment(req.createdAt).fromNow()} </td>
                    <td>
                      {req.shop?.name ? (
                        <Box>
                          <p style={{ fontWeight: "bold" }}>
                            {req?.shop?.name}
                          </p>
                          <p>PIC: {req?.shop?.pic?.name}</p>
                        </Box>
                      ) : (
                        "METACAR"
                      )}
                    </td>
                    <td> {req.grade} </td>

                    <td>
                      <span>{req.status}</span>
                    </td>
                    <td>
                      <span>{req.trackingCode}</span>
                    </td>
                    <td>
                      <img
                        className="img img-thumbnail"
                        style={{ maxHeight: "120px" }}
                        width={"auto"}
                        src={
                          req.images.filter(
                            (item) => item.subCategory === "Front right view"
                          )?.[0]?.thumbnail ?? "https://placehold.co/100x100"
                        }
                      />
                    </td>
                    <td>
                      <Typography variant="caption" sx={{ width: 100 }}>
                        {req.managementType}
                      </Typography>{" "}
                    </td>
                    <td>
                      <Button
                        type="button"
                        onClick={() => {
                          navigate(`/sell-requests/${req.id}`);
                        }}
                        label="Details"
                        variant="text"
                        color="info"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-around", px: 2 }}>
        <Button
          label="PREV"
          variant="outlined"
          disabled={currentPage == 0}
          icon={<FaChevronLeft />}
          onClick={() => {
            if (currentPage == 0) return;
            dispatch(
              fetchSellRequestApi({
                skip: (currentPage - 1) * PAGE_SIZE,
                take: PAGE_SIZE,
                ...sellRequest.queryParams,
              })
            );
            setPage(currentPage - 1);
          }}
        />
        <Box component={"span"} sx={{ alignItems: "center", p: 2 }}>
          {" Page "}
          {currentPage + 1} of {count ? Math.ceil(count / PAGE_SIZE) : "NA"}{" "}
        </Box>

        <Button
          label="NEXT"
          variant="outlined"
          icon={<FaChevronRight />}
          iconPosition="right"
          onClick={() => {
            dispatch(
              fetchSellRequestApi({
                skip: (currentPage + 1) * PAGE_SIZE,
                take: PAGE_SIZE,
                ...sellRequest.queryParams,
              })
            );
            setPage(currentPage + 1);
          }}
        />
      </Box>
    </Box>
  );
};
