export const cleanObject = (obj: any) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
export const convertArrayQPtoString = (
  searchParams: { [key: string]: string }[]
) => {
  return searchParams.map((item: any) => {
    return `${Object.keys(item)}=${Object.values(item)
      .toString()
      .toLowerCase()}`;
  });
};

export const convertQPtoArray = (searchParams: { [key: string]: string }) => {
  return Object.keys(searchParams).map((key) => {
    return {
      [key]: searchParams[key],
    };
  });
};

export const queryParamsToString = (searchParams: {
  [key: string]: string;
}) => {
  return `?${convertArrayQPtoString(convertQPtoArray(searchParams)).join("&")}`;
};
