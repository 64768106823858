import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSellRequestApi } from "./actions/sell-requests";
import { fetchBasicItems } from "./actions/basic-items";
import { IBasicItemsResponse } from "../../../../core/types/basic-items";
import {
  IAvailableOptions,
  ISellRequest,
} from "../../../../core/types/sell-request";

type InitialState = {
  sellRequests: ISellRequest[];
  count?: number;
  basicItems?: IBasicItemsResponse;
  loading: boolean;
  availableOptions?: IAvailableOptions;
  isSellRequestModalOpen: boolean;
  queryParams?: {
    id?: any;
    status?: string;
    brand?: string;
    model?: string;
    registration_no?: string;
    start_date?: string;
    end_date?: string;
    client?: string;
    tracking_code?: string;
    management?: string;
    phone?: string;
    tag?: string;
    email?: string;
    grade?: string;
    Models?: any;
    state_id?: any;
  };
};

const initialState: InitialState = {
  sellRequests: [],
  loading: false,
  isSellRequestModalOpen: false,
};

export const auth = createSlice({
  name: "sell-requests",
  initialState,
  reducers: {
    setIsSellRequestModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSellRequestModalOpen = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<Object>) => {
      state.queryParams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSellRequestApi.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchSellRequestApi.fulfilled, (state, action) => {
      state.sellRequests = action.payload?.cars;
      state.count = action.payload?.count;
      state.loading = false;
      state.availableOptions = action.payload?.availableOptions;
    });
    builder.addCase(fetchBasicItems.fulfilled, (state, action) => {
      state.basicItems = action.payload;
    });
  },
});

export const { setIsSellRequestModalOpen, setQueryParams } = auth.actions;

export default auth.reducer;
