import { Card } from "../../shared/card";
import "./styles.scss";

import { usePatchRequestMutation } from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { BuyRequestCarT } from "../../../pages/buy-request-details/types";

import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const statusData = [
  "fee-paid",
  "pending",
  "contacted",
  "loan-approved",
  "loan-rejected",
];
interface Inputs {
  status: string;
}

interface IProps {
  buyData: BuyRequestCarT;
  id?: string;
}

export function CarInfo(props: IProps) {
  const navigate = useNavigate();
  const { buyData, id } = props;

  return (
    <div className="car-detail-container">
      <Card header="Car Detail">
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <div className="col-sm-6 col-md-3 col-lg-1">
            <img
              src={buyData.car.selectedImage.thumbnail}
              alt="car"
              width={100}
              style={{ objectFit: "contain" }}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="brand"
              label="Brand"
              autoComplete="brand"
              autoFocus
              value={buyData.car?.make.brand}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="model"
              label="Model"
              autoComplete="model"
              autoFocus
              value={buyData.car?.make.model}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="brand"
              label="Variant"
              autoComplete="variant"
              autoFocus
              value={buyData.car?.make.variant}
            />
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="finalizedPrice"
              label="Finalized Price"
              autoComplete="finalizedPrice"
              autoFocus
              value={
                buyData.car?.finalized_price?.amount
                  ? parseFloat(buyData.car?.finalized_price?.amount).toFixed(2)
                  : "-"
              }
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="bookingFee"
              label="Booking Fee"
              autoComplete="bookingFee"
              autoFocus
              value={"500"}
            />
          </div>
        </Box>
        <LoadingButton
          sx={{ float: "right" }}
          onClick={() => navigate(`/sell-requests/${buyData.car.carId}`)}
          variant="contained"
        >
          Car Detail
        </LoadingButton>
      </Card>
    </div>
  );
}
