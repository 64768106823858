import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";

const staggeredBaseQuery = retry(
  async (args: any | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BACKEND_API,
      prepareHeaders: (headers) => {
        !args.formData && headers.set("Content-Type", "application/json");
        headers.set("Access-Control-Allow-Origin", "*");
        return headers;
      },
    })(args, api, extraOptions);

    if (result.error?.status === 403) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    }

    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 0,
  }
);

function getToken() {
  return localStorage.getItem("token") as string;
}

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: staggeredBaseQuery,

  endpoints: (builder) => ({
    getQuery: builder.query({
      query(url: string) {
        const token = getToken();
        return {
          url: url,
          headers: { Authorization: `Bearer ${token}` },
        };
      },
    }),
    postRequest: builder.mutation({
      query: ({ url, payload, formData }) => {
        const token = getToken();
        return {
          url: url,
          method: "POST",
          body: payload,
          headers: { Authorization: `Bearer ${token}` },

          formData: formData ? true : false,
        };
      },
    }),
    putRequest: builder.mutation({
      query: ({ url, payload, formData }) => {
        const token = getToken();
        return {
          url: url,
          method: "Put",
          body: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },

          formData: formData ? true : false,
        };
      },
    }),
    deleteRequest: builder.mutation({
      query: ({ url }) => {
        const token = getToken();
        return {
          url: url,
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        };
      },
    }),
    patchRequest: builder.mutation({
      query: ({ url, payload }) => {
        const token = getToken();
        return {
          url: url,
          method: "PATCH",
          body: payload,
          headers: { Authorization: `Bearer ${token}` },
        };
      },
    }),
  }),
});

export const {
  useGetQueryQuery,
  useLazyGetQueryQuery,
  usePostRequestMutation,
  usePutRequestMutation,
  useDeleteRequestMutation,
  usePatchRequestMutation,
} = apiSlice;
