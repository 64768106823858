import React, { useEffect, useState } from "react";
import { Card } from "../../shared/card";
import "./styles.scss";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import "react-datepicker/dist/react-datepicker.css";
import Pdf from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteRequestMutation,
  usePostRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import Dropzone from "react-dropzone";
interface Props {
  requestId: number;
  sellRequestData: sellRequestDataT;
  refetch: () => void;
}

export const GeneralPhotosSellRequest: React.FC<Props> = ({
  sellRequestData,
  refetch,
  requestId,
}) => {
  const dispatch = useDispatch();

  const [post, result] = usePostRequestMutation();
  const [deleteImage, deleteImageResult] = useDeleteRequestMutation();

  const [selectedGeneralImages, setSelectedGeneralImages] = useState<any[]>([]);

  const [currentImage, setCurrentImage] = useState<any[]>();

  const deleteLocalState = (index: number) => {
    const newArray = [...selectedGeneralImages];
    newArray.splice(index, 1);
    setSelectedGeneralImages(newArray);
  };

  const onDeleteImage = (id: number, index: number) => {
    deleteImage({
      url: `/internal/sell-requests/${requestId}/documents/${id}`,
    }).then((res: any) => {
      if ("error" in res && res.error && (res.error.data || res.error.error)) {
        dispatch(
          setToaster({
            isOpen: true,
            title:
              res.error?.data?.message ||
              res.error.error ||
              "Submission Failed",
            type: "error",
          })
        );
      } else {
        // refetch();

        deleteLocalState(index);
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully Updated",
            type: "success",
          })
        );
      }
    });
  };

  function onImageChange(e: any) {
    setCurrentImage([...e]);
    onSubmit(e);
  }

  useEffect(() => {
    currentImage &&
      setSelectedGeneralImages((old) => [...old, ...currentImage]);
  }, [currentImage]);

  const onSubmit = async (newImages: any[]) => {
    for (let i = 0; i < newImages.length; i++) {
      const formData = new FormData();
      if (!newImages[i]?.url) {
        formData.append("file", newImages[i]);
        await post({
          url: `internal/sell-requests/${requestId}/documents`,
          payload: formData,
          formData: true,
        }).then((res: any) => {
          if (
            "error" in res &&
            res.error &&
            (res.error.data || res.error.error)
          ) {
            dispatch(
              setToaster({
                isOpen: true,
                title:
                  res.error?.data?.message ||
                  res.error.error ||
                  "Submission Failed",
                type: "error",
              })
            );
          } else {
            // setTimeout(() => {
            //   refetch();
            // }, 4000);

            if (i + 1 === newImages.length) {
              refetch();
            }

            dispatch(
              setToaster({
                isOpen: true,
                title: "Successfully Updated",
                type: "success",
              })
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (sellRequestData?.documents && sellRequestData?.documents.length) {
      setSelectedGeneralImages(sellRequestData?.documents);
    } else {
      setSelectedGeneralImages([]);
    }
  }, [sellRequestData?.documents]);

  return (
    <div className="seller-detail-container">
      <Card header="General Car Documents">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            p={2}
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              {result.isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    my: 6,
                  }}
                >
                  <CircularProgress size={60} sx={{ color: "#F5903D" }} />
                </Box>
              ) : (
                <Dropzone onDrop={onImageChange}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <Box
                        {...getRootProps()}
                        sx={{
                          width: 400,
                          height: 140,

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(0,0,0,0.1)",
                          my: 2,
                        }}
                      >
                        <input
                          id="upload-image-sell-general"
                          type="file"
                          multiple
                          style={{ display: "none", width: "100%" }}
                          accept="application/pdf, image/png, image/gif, image/jpeg , image/jpg,image/heic"
                          {...getInputProps()}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <AddPhotoAlternate
                            style={{ cursor: "copy" }}
                            fontSize="large"
                            sx={{
                              width: "60px",
                              height: "60px",
                              color: "gray",
                            }}
                          />{" "}
                          <Typography>Drag & Drop</Typography>
                        </Box>
                      </Box>
                    </section>
                  )}
                </Dropzone>
              )}

              {selectedGeneralImages.length ? (
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  {selectedGeneralImages.map((image, index) => {
                    return (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {image?.url?.toLowerCase()?.includes(".pdf") ? (
                          <a
                            href={
                              image.url ? image.url : URL.createObjectURL(image)
                            }
                            target="_blank"
                          >
                            <Box
                              sx={{
                                width: 200,
                                height: 200,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: "18px",
                                justifyContent: "center",
                                p: 4,
                                gap: 1,
                                border: "1px solid gray",
                              }}
                            >
                              <Typography>{image.note}</Typography>
                              <Pdf sx={{ fontSize: 80 }} />
                            </Box>
                          </a>
                        ) : (
                          <a
                            href={
                              image.url ? image.url : URL.createObjectURL(image)
                            }
                            target="_blank"
                          >
                            <img
                              src={
                                image.url
                                  ? image.url
                                  : URL.createObjectURL(image)
                              }
                              width={200}
                              height={200}
                              loading="lazy"
                              style={{ objectFit: "cover" }}
                            />
                          </a>
                        )}
                        {!result.isLoading && (
                          <IconButton
                            onClick={() => onDeleteImage(image.id, index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Box>

          {/* <Button
            sx={{ float: "right", height: 40, padding: 0 }}
            onClick={onSubmit}
            variant="contained"
          >
            Upload
          </Button> */}
        </Box>
      </Card>
    </div>
  );
};
