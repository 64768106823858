"use client";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { useGetQueryQuery } from "../../../../utility/redux/api/api-slice";
import { AutoCompleteFormHook } from "../../../components/shared/AutoCompleteFormHook";
import { SelectFormHook } from "../../../components/shared/SelectFormHook";
import {
  Feature,
  basicsT,
  sellRequestDataT,
} from "../../sell-request-details/types";
import { FC, useEffect } from "react";
import dayjs from "dayjs";

interface Inputs {
  brandId: { id: number; title: string };
  modelId: { id: number; title: string };
  variantId: { id: number; title: string };
  manufactureDate: {
    year: number;
    month: number;
    day?: number;
  };
  gearId: number;
  colorId: number;
  bodyId: number;
  mileage: number;
  price: string;
  registrationNo: string;
  vinNo: string;
  engineCC: string;
  addressType: string;
  stateId: { id: number; title: string };
  city: string;
  postalCode: string;
  addressLine: string;
  phoneNumber: string;
  email: string;
  name: string;
}

interface Props {
  basicData: basicsT;
  sellRequestData: sellRequestDataT;
}
export const InspectionDefault: FC<Props> = ({
  basicData,
  sellRequestData,
}) => {
  const { data: { list: stateBasics } = {} } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsLocations}/malaysia`
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    const city = sellRequestData.seller.address?.length
      ? sellRequestData.seller?.address[0].city
      : undefined;
    const addressType = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].type
      : undefined;
    const state = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].state
      : undefined;
    const postalCode = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].postalCode
      : undefined;
    const addressLine = sellRequestData.seller.address?.length
      ? sellRequestData.seller.address[0].address
      : undefined;
    reset({
      price: Number(sellRequestData.sellerFinalizedPrice.amount).toFixed(2),
      bodyId: basicData?.bodies.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.body
      )[0].id,
      modelId: basicData?.models.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.make.model
      )[0],
      brandId: basicData?.brands.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.make.brand
      )[0],
      colorId: basicData?.colors.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.color.name
      )[0].id,
      engineCC: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "cc"
      )[0]?.value,
      gearId: basicData?.gears.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.gear
      )[0].id,
      manufactureDate: {
        year: +dayjs(sellRequestData.make.manufactureDate).year(),
        month: +dayjs(sellRequestData.make.manufactureDate).month() + 1,
        day: +dayjs(sellRequestData.make.manufactureDate).day(),
      },
      variantId: basicData?.variants.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.make.variant
      )[0],
      registrationNo: sellRequestData.registrationNo,

      vinNo: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "VIN_NO"
      )[0]?.value,
      mileage: sellRequestData.mileage,
      city,
      addressType,
      stateId: stateBasics?.filter((item: { id: number; title: string }) => {
        return item.title === state;
      })[0],
      postalCode,
      addressLine,
      name: sellRequestData.seller.name,
      email: sellRequestData.seller.email,
      phoneNumber: sellRequestData.seller.phone,
    });
  }, [reset, sellRequestData]);

  if (!sellRequestData) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 10,
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {};

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ my: 4 }}>
        <Box
          display={"flex"}
          gap={10}
          width={"100%"}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Box flexGrow={1} width={"100%"}>
            <Typography
              variant="h6"
              color={"rgba(0, 0, 0, 0.7)"}
              borderBottom={"2px solid rgba(0, 0, 0, 0.3)"}
              mb={3}
            >
              Car Detail
            </Typography>

            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <TextField
                margin="normal"
                fullWidth
                label="Price RM"
                {...register("price")}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                {...register("registrationNo")}
                margin="normal"
                fullWidth
                id="registrationNo"
                label="Registration Number"
                autoComplete="registrationNo"
                autoFocus
                error={!!errors?.registrationNo?.message}
                helperText={errors?.registrationNo?.message}
                sx={{ m: 0 }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <AutoCompleteFormHook
                options={basicData?.brands}
                readOnly
                name="brandId"
                label="Brands"
                control={control}
                defaultValue={""}
              />

              <AutoCompleteFormHook
                options={basicData?.models}
                readOnly
                name="modelId"
                label="Models"
                control={control}
                defaultValue={""}
              />

              <AutoCompleteFormHook
                readOnly
                options={basicData?.variants}
                name="variantId"
                label="Variant"
                control={control}
                defaultValue={""}
              />

              <SelectFormHook
                id="bodyId"
                name="bodyId"
                label="Body"
                control={control}
                defaultValue={""}
                readOnly
              >
                {basicData.bodies.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{ textAlign: "left" }}
                      value={item.id}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>

              <SelectFormHook
                id="colorId"
                name="colorId"
                label="Color"
                control={control}
                defaultValue={""}
                readOnly
              >
                {basicData.colors.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>

              <SelectFormHook
                id="gearId"
                name="gearId"
                label="Transmission"
                control={control}
                defaultValue={""}
                readOnly
              >
                {basicData.gears.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.title.toString().toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>

              <Box>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    {...register("manufactureDate.year")}
                    margin="normal"
                    fullWidth
                    id="year"
                    label="Manufacture Year"
                    autoComplete="year"
                    autoFocus
                    sx={{ mt: 1 }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box flexGrow={1} width={"100%"}>
            <Typography
              variant="h6"
              color={"rgba(0, 0, 0, 0.7)"}
              mb={5}
              borderBottom={"2px solid rgba(0, 0, 0, 0.3)"}
            >
              Seller Information
            </Typography>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <TextField
                {...register("name")}
                margin="normal"
                fullWidth
                id="name"
                label="Seller Name"
                autoComplete="name"
                autoFocus
                error={!!errors?.name?.message}
                helperText={errors?.name?.message}
                sx={{ mt: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                {...register("email")}
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                sx={{ mt: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                {...register("phoneNumber")}
                margin="normal"
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                autoComplete="phoneNumber"
                autoFocus
                error={!!errors?.phoneNumber?.message}
                helperText={errors?.phoneNumber?.message}
                sx={{ mt: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />

              <AutoCompleteFormHook
                readOnly
                options={[]}
                name="stateId"
                label="State"
                control={control}
                defaultValue={""}
              />

              <TextField
                {...register("city")}
                margin="normal"
                fullWidth
                id="city"
                label="City"
                autoComplete="city"
                autoFocus
                error={!!errors?.city?.message}
                helperText={errors?.city?.message}
                sx={{ mt: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />

              <SelectFormHook
                id="addressType"
                name="addressType"
                label="Address Type"
                control={control}
                defaultValue={""}
                readOnly
              >
                {["WORK", "HOME"].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>

              <TextField
                {...register("postalCode")}
                margin="normal"
                fullWidth
                id="postalCode"
                label="Postal Code"
                autoComplete="postalCode"
                autoFocus
                error={!!errors?.postalCode?.message}
                helperText={errors?.postalCode?.message}
                sx={{ mt: 1 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                {...register("addressLine")}
                margin="normal"
                fullWidth
                id="addressLine"
                label="Address"
                autoComplete="postalCode"
                autoFocus
                error={!!errors?.addressLine?.message}
                helperText={errors?.addressLine?.message}
                sx={{ mt: 1 }}
                multiline
                rows={3}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
