import { FC } from "react";
import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { useGetQueryQuery } from "../../../../utility/redux/api/api-slice";
import {
  Column,
  InspectionT,
  TableDataT,
} from "../../sell-request-details/types";
import { InspectionModal } from "./inspection-modal";

interface Props {
  report_id: string;
  sellRequestId: string;
}

export const InspectionCar: FC<Props> = ({ report_id, sellRequestId }) => {
  const {
    data: list,
    refetch,
    isLoading,
  } = useGetQueryQuery(`internal/inspection-reports/${report_id}`);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);

  const [tableData, setTableData] = React.useState<TableDataT[] | undefined>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(400);

  const tableRows: InspectionT = list?.inspection;
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const columns: readonly Column[] = [
    { id: "fakeId", label: "ID", minWidth: 10 },
    { id: "catagory", label: "Category", minWidth: 100 },
    { id: "subCatagory", label: "SubCategory", minWidth: 100 },
    { id: "title", label: "Title", minWidth: 80, align: "left" },

    {
      id: "status",
      label: "Status",
      minWidth: 60,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },

    {
      id: "remark",
      label: "Remark",
      minWidth: 240,

      format: (value: number) => value.toLocaleString("en-US"),
    },

    {
      id: "images",
      label: "Images",
      minWidth: 120,

      // format: (value: number) => value ("en-US"),
    },
  ];

  React.useEffect(() => {
    if (tableRows) {
      const createTableData = tableRows.details.map((row, index) => {
        return {
          fakeId: row.item.sortOrder,
          id: row.id,
          catagory: row.item.catagory,
          subCatagory: row.item.subCatagory,
          remark: row.remark,
          title: row.item.title,
          status: row.status,
          images: row.images,
        };
      });

      setTableData(createTableData);
    }
  }, [tableRows]);

  const sortHandler = () => {
    tableData?.sort((item) => (item.status === "pass" ? 1 : -1));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSelectRowHandler = (id: number) => {
    setIsModalOpen(true);
    setSelectedId(id);
  };

  const onNextHandler = () => {
    setSelectedId((previousId) => previousId + 1);
  };

  const onBackHandler = () => {
    setSelectedId((previousId) => previousId - 1);
  };

  const onCloseModal = () => {
    refetch();
    setIsModalOpen(false);
  };

  const onRefetchHandler = () => {
    refetch().then(() => {
      setIsModalOpen(false);

      setTimeout(() => {
        setIsModalOpen(true);
      }, 10);
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {selectedId && tableData ? (
        <InspectionModal
          tableData={tableData}
          isOpen={isModalOpen}
          onCloseModal={onCloseModal}
          currentId={selectedId}
          onBackHandler={onBackHandler}
          onNextHandler={onNextHandler}
          report_id={report_id}
          sellRequestId={sellRequestId}
          refetch={onRefetchHandler}
          isLoading={isLoading}
        />
      ) : null}
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length &&
              tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value: any = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => onSelectRowHandler(row.fakeId)}
                          >
                            {column.id === "images" && value.length ? (
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                gap={0.1}
                              >
                                <img
                                  src={value[0].url}
                                  width={60}
                                  style={{ marginRight: "6px" }}
                                />

                                <Typography
                                  textAlign={"center"}
                                  sx={{
                                    width: 22,
                                    backgroundColor: "orange",
                                    color: "#fff",
                                    borderRadius: 40,
                                  }}
                                >
                                  {value.length}
                                </Typography>
                              </Box>
                            ) : column.id === "status" ? (
                              column.format && (
                                <Typography
                                  variant="subtitle1"
                                  textAlign={"center"}
                                  sx={{
                                    p: 0.6,
                                    borderRadius: 2,
                                    backgroundColor:
                                      value === "pass"
                                        ? "green"
                                        : value === "n/a"
                                        ? "gray"
                                        : "red",
                                    color:
                                      value === "pass"
                                        ? "#fff"
                                        : value === "N/A"
                                        ? "gray"
                                        : "#fff",
                                  }}
                                >
                                  {column?.format(value)?.toUpperCase()}
                                </Typography>
                              )
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
