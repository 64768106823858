import { Container } from "@mui/material";

import { FC, useEffect, useState } from "react";
import { ReportT } from "./types";

import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";

import TableRenderers from "react-pivottable/TableRenderers";

import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";

interface Props {
  data: any;
  type: string;
}

export const ReportTable: FC<Props> = ({ data, type }) => {
  const PlotlyRenderers = createPlotlyRenderers(Plot);

  const defaultState =
    type === "userReport"
      ? {
          rows: [],
          cols: ["date"],
          aggregatorName: "Integer Sum",
          vals: ["count"],
          rendererName: "Grouped Column Chart",
        }
      : type === "average"
      ? {
          rows: ["brand", "model"],
          cols: [],
          aggregatorName: "Integer Sum",
          vals: ["avg"],
        }
      : {
          rows: ["brand"],
          cols: ["status"],
          aggregatorName: "Integer Sum",
          vals: ["count"],
        };

  const [state, setState] = useState<any>(defaultState);
  console.log(state, "state");

  const [tableData, setTableData] = useState<any>();

  const createRows = () => {
    const newData = data.data.map((item: any) => ({
      ...item,
      count: +item.count,
      avg: +item?.avg,
    }));

    console.log(newData, "newData");

    setTableData(newData);
  };

  useEffect(() => {
    createRows();
  }, [data]);

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      {tableData && (
        <PivotTableUI
          data={tableData}
          onChange={(s) => setState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...state}
        />
      )}{" "}
    </Container>
  );
};
