import React, { useEffect, useState } from "react";
import "./styles.scss";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { Button } from "../../components/shared/button";
import moment from "moment";
import Loading from "../../components/General/loading/loading";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { PageHeader } from "../../components/shared/page-header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RequestFilter } from "../../components/sell-requests/requests-filter";
import { Toaster } from "../../components/General/Toaster";
import { Box, Typography } from "@mui/material";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";
import { BuyRequestT } from "./types";

interface filterType {
  model: string;
  brand: string;
  end_date: string;
  start_date: string;
  status: string;
  tracking_code: string;
}

const PAGE_SIZE = 10;

export const BuyRequestList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageParams = searchParams?.get("page")
    ? Number(searchParams.get("page"))
    : 0;
  const toaster = useAppSelector((state) => state.authReducer.toaster);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentPage, setPage] = useState(currentPageParams);

  const [filterParams, setFilterParams] = useState<any>([]);

  let url = `${REST_STAFF_ENDPOINTS.buyRequest}`;

  if (filterParams.length) {
    const searchParams = filterParams.join("&");
    url = `${REST_STAFF_ENDPOINTS.buyRequest}?${searchParams}`;
  }

  if (currentPage) {
    url = `${REST_STAFF_ENDPOINTS.buyRequest}?skip=${
      currentPage * PAGE_SIZE
    }&take=${PAGE_SIZE}`;
  }

  const { data: listData } = useGetQueryQuery(url);
  const buyRequestListData: BuyRequestT = listData;

  useEffect(() => {
    setSearchParams(
      `?${new URLSearchParams({ page: currentPage.toString() })}`
    );
  }, [currentPage]);

  const handleFilterRequest = (filter: filterType) => {
    const params = [];
    const filterKeys = Object.keys(filter);

    for (const key of filterKeys) {
      //@ts-ignore
      if (filter[key] as any) {
        //@ts-ignore
        params.push(`${key}=${filter[key]}`);
      }
    }
    setFilterParams(params);
  };

  if (!buyRequestListData?.list) {
    return <Loading />;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <PageHeader label="Buy Requests" />

      <div className="">
        <div className="filter-panel">
          <RequestFilter
            buyReq
            countBuyReq={buyRequestListData.list?.length}
            onChangeFilter={(newFilter) => {
              setPage(0);
              handleFilterRequest(newFilter);

         
            }}
          />
        </div>
        <Box sx={{ p: 2 }}>
          <table className="table table-sm table-hover">
            <thead className="bg-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Booking ID</th>
                <th scope="col">Make</th>
                <th scope="col">Price</th>
                <th scope="col">Seller Info</th>
                <th scope="col">Registration No</th>
                <th scope="col">Created Date</th>
                <th scope="col">Last Update</th>
                <th scope="col">Status</th>
                <th scope="col">Tracking Code</th>
                <th scope="col">Image</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle" }}>
              {buyRequestListData?.list?.map((req, index) => (
                <tr key={req.id}>
                  <th scope="row">{index + 1 + currentPage * PAGE_SIZE}</th>

                  <td style={{ width: "100px" }}>
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.id}
                    </Typography>
                  </td>
                  <td style={{ width: "100px" }}>
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.car.make.brand} {req.car.make.model}{" "}
                      {req.car.make.variant}
                    </Typography>
                  </td>

                  <td>
                    {req.car.finalized_price.amount
                      ? (+req.car.finalized_price.amount).toFixed(2)
                      : 0.0}{" "}
                  </td>

                  <td style={{ width: "100px" }}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="caption">
                        {" "}
                        {req.seller.name}
                      </Typography>
                      <Typography variant="caption">
                        {req.seller.email}
                      </Typography>
                      <Typography variant="caption">
                        {" "}
                        {req.seller.phone}
                      </Typography>
                    </Box>{" "}
                  </td>

                  <td>
                    {" "}
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.car.registrationNo}
                    </Typography>{" "}
                  </td>
                  <td> {moment(req.createdAt).fromNow()} </td>
                  <td>{moment(req.updatedAt).fromNow()} </td>
                  <td>
                    <span>{req.status}</span>
                  </td>
                  <td>
                    <span>{req.trackingCode}</span>
                  </td>
                  <td>
                    <img
                      style={{ maxHeight: "120px" }}
                      className="img img-thumbnail"
                      src={req.car.selectedImage.thumbnail}
                    />
                  </td>
                  <td>
                    {" "}
                    <Button
                      type="button"
                      onClick={() => {
                        navigate(`/buy-request/${req.id}`);
                      }}
                      label="Details"
                      variant="outlined"
                    />{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-around", px: 2 }}>
        <Button
          label="PREV"
          variant="outlined"
          disabled={currentPage == 0}
          icon={<FaChevronLeft />}
          onClick={() => {
            if (currentPage == 0) return;
            setPage(currentPage - 1);
          }}
        />
        <Box component={"span"} sx={{ alignItems: "center", p: 2 }}>
          {" Page "}
          {currentPage + 1} of{" "}
          {listData.list.length
            ? Math.ceil(listData.list.length / PAGE_SIZE)
            : "NA"}{" "}
        </Box>

        <Button
          label="NEXT"
          variant="outlined"
          icon={<FaChevronRight />}
          iconPosition="right"
          onClick={() => {
            setPage(currentPage + 1);
          }}
        />
      </Box>
    </Box>
  );
};
