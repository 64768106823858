import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postLogin } from "./actions/auth";
const baseUrl = process.env.NEXT_PUBLIC_BACKEND_API;

export type toasterDataType = {
  isOpen: boolean;
  title: string;
  description?: string;
  type?: "error" | "warning" | "info" | "success";
};

type InitialState = {
  value: AuthState;
  loading: boolean;
  toaster: toasterDataType;
};

type AuthState = {
  isAuth: boolean;
  username?: string;
  token?: string;
};

const initialState: InitialState = {
  value: {
    isAuth: false,
    username: "",
  } as AuthState,
  loading: false,
  toaster: {
    isOpen: false,
    title: "",
    type: "success",
  },
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginIn: (
      state,
      dto: PayloadAction<{
        status: number;
        username: string;
        access: {
          token: string;
          expireIn: number;
        };
      }>
    ) => {
      return {
        ...state,
        value: {
          isAuth: dto.payload != null,
          token: dto.payload.access?.token,
          username: dto.payload.username,
        },
      };
    },
    logOut: () => {
      return initialState;
    },

    setToaster: (state, action: PayloadAction<toasterDataType>) => {
      state.toaster = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postLogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      state.loading = false;

      window.localStorage.setItem("token", action.payload.access.token);
      state.value = {
        isAuth: true,
        token: action.payload.access.token,
        username: "admin",
      };
    });
  },
});

export const { logOut, loginIn, setToaster } = auth.actions;
export default auth.reducer;
