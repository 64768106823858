import React, { useEffect, useState } from "react";
import { Card } from "../../shared/card";
import "./styles.scss";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import { usePostRequestMutation } from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import LoadingButton from "@mui/lab/LoadingButton";

const INTERVAL_TIME = 3000;
interface Inputs {
  remark: string;
  appointments: {
    type: string;
    times: [
      {
        timestamp: string;
        duration: number;
      }
    ];
  };
}
interface Props {
  requestId: number;
  sellRequestData: sellRequestDataT;
  refetch: () => void;
}

export const InsuranceClaim: React.FC<Props> = ({ sellRequestData, refetch, requestId }) => {
  const dispatch = useDispatch();
  const [post, result] = usePostRequestMutation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const claimHistoryUrl = sellRequestData.insuranceClaims?.[0]?.document.url;

  const onConfirmHandler = () => {
    onGenerateInsuranceClaim();
    setIsModalOpen(false);
  };

  const onGenerateInsuranceClaim = () => {
    post({
      url: `${REST_STAFF_ENDPOINTS.sellRequestOps}/${requestId}/fetch-insurance-claim`,
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Success",
            type: "success",
          })
        );
        refetch();
      }
    });
  };

  useEffect(() => {
    if (
      sellRequestData?.insuranceClaims?.[0] &&
      sellRequestData.insuranceClaims[0]?.document?.status === "generating"
    ) {
      setIsLoading(true);
      const intervalCall = setInterval(() => {
        refetch();
      }, INTERVAL_TIME);
      return () => {
        // clean up
        clearInterval(intervalCall);
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [sellRequestData?.insuranceClaims?.[0]]);

  return (
    <div className="seller-detail-container">
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Do want to generate a insurance claim? "}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button autoFocus onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onConfirmHandler} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Card header="Insurance Claim">
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          {sellRequestData?.insuranceClaims?.[0]?.document && claimHistoryUrl ? (
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} gap={2}>
                <Typography>Insurance Claim Date</Typography>
                <Typography fontWeight={"bold"}>
                  {dayjs(sellRequestData.insuranceClaims[0]?.document?.generatedDate).format(
                    "DD MMM YY"
                  )}
                </Typography>
              </Box>
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Typography>Insurance Claim Doc:</Typography>

                <IconButton
                  onClick={() =>
                    sellRequestData?.insuranceClaims?.[0]?.document?.url &&
                    window.open(
                      sellRequestData?.insuranceClaims[0]?.document?.url,
                      "_blank",
                      "fullscreen=yes"
                    )
                  }
                >
                  <PictureAsPdf color="warning" />
                </IconButton>
              </Box>
            </Box>
          ) : isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={100} sx={{ color: "#F5903D" }} />{" "}
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>No Data</Typography>
            </Box>
          )}

          <Box>
            <LoadingButton
              disabled={sellRequestData?.insuranceClaims?.[0]?.document?.status === "generated"}
              loading={result.isLoading}
              sx={{ m: 4, mt: 0, float: "right" }}
              type="submit"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
            >
              {sellRequestData?.insuranceClaims?.[0]?.document?.status === "generated"
                ? "Already Generated"
                : isLoading
                ? "Generating"
                : "Generate Insurance Claim"}
            </LoadingButton>
          </Box>
        </Box>
      </Card>
    </div>
  );
};
