import { Box, Typography } from "@mui/material";

export type PageHeaderProps = {
  label: string;
};
export function PageHeader({ label }: PageHeaderProps) {
  return (
    <Box sx={{ backgroundColor: "darkblue", color: "#fff", p: 2 }}>
      <Typography variant="h6" fontWeight={"bold"}>
        {label}
      </Typography>
    </Box>
  );
}
