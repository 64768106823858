import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "./auth";
import { ENDPOINTS, makeHeader } from "./endpoints";

const baseUrl = process.env.NEXT_PUBLIC_BACKEND_API;
const sellRequestBaseUrl = baseUrl + ENDPOINTS.SELL_REQUESTS;

export const fetchBasicItems = createAsyncThunk(
  "GET /basics-items",
  async (thunkAPI) => {
    const response = await axios.get(sellRequestBaseUrl, {
      headers: makeHeader(),
    });
    return response.data;
  }
);
