import { Controller, useForm } from "react-hook-form";
import { Card } from "../../shared/card";
import "./styles.scss";

import {
  useGetQueryQuery,
  usePatchRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import { Box, InputLabel, MenuItem, TextField } from "@mui/material";
import { SelectFormHook } from "../../shared/SelectFormHook";
import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Feature,
  basicsT,
  sellRequestDataT,
} from "../../../pages/sell-request-details/types";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { basicYears } from "../NewSellRequestModal/NewSellRequestModal";

interface Inputs {
  requestId: number;
  brandId: { id: number; title: string };
  modelId?: { id: number; title: string };
  variantId?: { id: number; title: string };
  manufactureDate: {
    year: number;
    month: number;
    day?: number;
  };
  gearId: number;
  colorId: number;
  fuelId: number;
  bodyId: number;
  mileage: number;
  engineId: number;
  assembledId: number;
  registrationNo: string;
  registrationDate: Date | string;
  vinNo: string;
  engineNo: string;
  engineCC: string;
  noOfOwners: string;
  hasBooklet: boolean | string;
  hasSpareKey: string;
  noSpareKey: string;
  noOfSeat: string;
  noOfGear: string;
  drivenWheel: string;
  registrationType: string;
  principleWarranty: string;
  extendedWarranty: string;
  management: string;
}

interface Props {
  sellRequestData: sellRequestDataT;
  requestId: number;
}

export const managementType = [
  { name: "METACAR certified & Managed", value: "certified-managed" },
  { name: "METACAR certified & Self Managed", value: "certified-only" },
  { name: "Uncertified & Self Managed", value: "uncertified" },
];

export const CarDetail: FC<Props> = ({ requestId, sellRequestData }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const [patchData, result] = usePatchRequestMutation();
  const [isAssembleIdReadOnly, setIsAssembleIdReadOnly] = useState(false);

  const dispatch = useDispatch();

  const brand = watch("brandId");
  const model = watch("modelId");
  const registrationT = watch("registrationType");

  const { data: basic_data } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}`
  );
  const { data: basicModels } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsSearch}/models?parentId=${brand?.id}`,
    {
      skip: !brand,
    }
  );
  const { data: basicVariants } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsSearch}/variants?parentId=${model?.id}`,
    {
      skip: !model,
    }
  );
  const basicData: basicsT = basic_data;

  useEffect(() => {
    if (
      brand &&
      sellRequestData &&
      sellRequestData.make.brand !== brand?.title
    ) {
      setValue("modelId", undefined);
      setValue("variantId", undefined);
    }
  }, [brand]);

  useEffect(() => {
    const newData = basicVariants?.variants.filter(
      (item: { id: number; title: string }) =>
        item.title === sellRequestData.make.variant
    )[0];
    setValue("variantId", newData);
  }, [basicVariants]);

  useEffect(() => {
    reset({
      assembledId: basicData?.assembled.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.assembled
      )[0]?.id,
      bodyId: basicData?.bodies.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.body
      )[0]?.id,
      modelId: basicData?.models.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.make.model
      )[0],
      brandId: basicData?.brands.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.make.brand
      )[0],
      colorId: basicData?.colors.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.color.name
      )[0]?.id,

      engineCC: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "engineCC"
      )[0]?.value,
      engineNo: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "engineNo"
      )[0]?.value,
      fuelId: basicData?.fuels.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.fuel
      )[0]?.id,
      gearId: basicData?.gears.filter(
        (item: { id: number; title: string }) =>
          item.title === sellRequestData.gear
      )[0]?.id,
      manufactureDate: {
        year: +dayjs(sellRequestData.make.manufactureDate).year(),
        month: 1,
        day: 1,
      },

      noOfOwners: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "noOfOwners"
      )[0]?.value,

      registrationNo: sellRequestData.registrationNo,
      registrationDate: sellRequestData.registrationDate
        ? new Date(sellRequestData.registrationDate)
        : "",
      hasBooklet:
        sellRequestData?.features?.filter(
          (item: Feature) => item.name === "hasBooklet"
        )[0]?.value === "true"
          ? "yes"
          : "no",

      hasSpareKey:
        sellRequestData?.features?.filter(
          (item: Feature) => item.name === "hasSpareKey"
        )[0]?.value === "true"
          ? "yes"
          : "no",

      noOfSeat: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "noOfSeat"
      )[0]?.value,
      noOfGear: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "noOfGear"
      )[0]?.value,
      drivenWheel: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "drivenWheel"
      )[0]?.value,

      registrationType: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "registrationType"
      )[0]?.value,
      principleWarranty: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "principleWarranty"
      )[0]?.value,
      extendedWarranty: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "extendedWarranty"
      )[0]?.value,
      vinNo: sellRequestData?.features?.filter(
        (item: Feature) => item.name === "vinNo"
      )[0]?.value,

      mileage: sellRequestData.mileage,
      management: sellRequestData.managementType,
    });
  }, [basicData, reset]);

  useEffect(() => {
    if (registrationT === "UnRegistered") {
      setIsAssembleIdReadOnly(true);
      setValue("assembledId", 3);
    } else {
      setIsAssembleIdReadOnly(false);
    }
  }, [registrationT]);

  const onSubmit = async (data: Inputs) => {
    const payload = {
      assembledId: data.assembledId,
      bodyId: data.bodyId,
      brandId: data.brandId?.id,
      colorId: data?.colorId,
      engineCC: data?.engineCC,
      engineId: data?.engineId,
      engineNo: data.engineNo,
      fuelId: data.fuelId,
      gearId: data?.gearId,
      manufactureDate: {
        year: data.manufactureDate.year,
        month: data.manufactureDate.month,
        day: data.manufactureDate.day,
      },
      modelId: data.modelId?.id,
      variantId: data.variantId?.id ? data.variantId?.id : 0,
      noOfOwners: data.noOfOwners,
      noSpareKey: data.noSpareKey,
      registrationNo: data.registrationNo,
      registrationDate: data.registrationDate,
      hasBooklet: data.hasBooklet === "yes" ? true : false,
      hasSpareKey: data.hasSpareKey === "yes" ? true : false,
      vinNo: data.vinNo,
      requestId: requestId,
      mileage: data.mileage,
      noOfSeat: data.noOfSeat,
      noOfGear: data.noOfGear,
      drivenWheel: data.drivenWheel,
      registrationType: data.registrationType,
      principleWarranty: data.principleWarranty,
      extendedWarranty: data.extendedWarranty,
      management: data.management,
    };

    const result: any = await patchData({
      url: "internal/sell-requests/operations/car-info",
      payload,
    });

    if ("error" in result && result.error && result.error.data) {
      dispatch(
        setToaster({
          isOpen: true,
          title: result.error?.data?.message || "Submission Failed",
          type: "error",
        })
      );
    } else {
      dispatch(
        setToaster({
          isOpen: true,
          title: "Successfully car sell requested is submitted",
          type: "success",
        })
      );
    }

    if ("error" in result) {
      result.error &&
        dispatch(
          setToaster({
            isOpen: true,
            title: "Something went wrong",
            type: "error",
          })
        );
    }
  };

  if (!basicData) {
    return <p>..Loading</p>;
  }

  return (
    <div className="car-detail-container">
      <Card header="Car Detail">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <div className="row  gap-3">
            <div className="col-sm-6 col-md-3 col-lg-2">
              <AutoCompleteFormHook
                options={basicData?.brands ?? []}
                disabled={false}
                name="brandId"
                label="Brands"
                control={control}
                defaultValue={""}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <AutoCompleteFormHook
                options={basicModels?.models ?? []}
                disabled={false}
                name="modelId"
                label="Models"
                control={control}
                defaultValue={""}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <AutoCompleteFormHook
                options={basicVariants?.variants ?? []}
                disabled={false}
                name="variantId"
                label="Variant"
                control={control}
                defaultValue={""}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="bodyId"
                name="bodyId"
                label="Body Type"
                control={control}
                defaultValue={""}
              >
                {basicData.bodies.map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("registrationNo")}
                margin="normal"
                fullWidth
                id="registrationNo"
                label="Registration No"
                autoComplete="registrationNo"
                autoFocus
                error={!!errors?.registrationNo?.message}
                helperText={errors?.registrationNo?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="colorId"
                name="colorId"
                label="Color"
                control={control}
                defaultValue={""}
              >
                {basicData.colors.map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="engineId"
                name="engineId"
                label="Engine Type"
                control={control}
                defaultValue={""}
              >
                {basicData.engines.map(
                  (item: { id: number; title: string }) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  }
                )}
              </SelectFormHook>
            </div> */}
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="fuelId"
                name="fuelId"
                label="Fuel Type"
                control={control}
                defaultValue={""}
              >
                {basicData.fuels.map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("engineCC")}
                margin="normal"
                fullWidth
                id="engineCC"
                label="Engine CC"
                autoComplete="engineCC"
                autoFocus
                error={!!errors?.engineCC?.message}
                helperText={errors?.engineCC?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="gearId"
                name="gearId"
                label="Transmission"
                control={control}
                defaultValue={""}
              >
                {basicData.gears.map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title.toString().toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("mileage")}
                margin="normal"
                fullWidth
                id="mileage"
                label="Mileage KM"
                autoComplete="mileage"
                autoFocus
                error={!!errors?.mileage?.message}
                helperText={errors?.mileage?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="management"
                name="management"
                label="Management"
                control={control}
                defaultValue={""}
                required
              >
                {managementType.map((item: { name: string; value: string }) => {
                  return (
                    <MenuItem value={item.value}>
                      {item.name.toString().toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div> */}
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="manufactureDate.year"
                name="manufactureDate.year"
                label="Manufacturing Year"
                control={control}
                defaultValue={""}
                required
              >
                {basicYears.map((item: any) => {
                  return (
                    <MenuItem value={item.id}>
                      {item.title.toString().toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="registrationType"
                name="registrationType"
                label="Registration Type"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "Private" },
                  { id: 2, title: "Corporate" },
                  { id: 2, title: "UnRegistered" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="assembledId"
                name="assembledId"
                label="Assembled Type"
                control={control}
                defaultValue={""}
                readOnly={isAssembleIdReadOnly}
              >
                {basicData.assembled.map(
                  (item: { id: number; title: string }) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  }
                )}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("vinNo")}
                margin="normal"
                fullWidth
                id="vinNo"
                label="Vin No"
                autoComplete="vinNo"
                autoFocus
                error={!!errors?.vinNo?.message}
                helperText={errors?.vinNo?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("engineNo")}
                margin="normal"
                fullWidth
                id="engineNo"
                label="Engine No"
                autoComplete="vinNo"
                autoFocus
                error={!!errors?.engineNo?.message}
                helperText={errors?.engineNo?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("noOfGear")}
                margin="normal"
                type="number"
                fullWidth
                id="noOfGear"
                label="No of Gear"
                autoComplete="noOfSeat"
                autoFocus
                error={!!errors?.noOfGear?.message}
                helperText={errors?.noOfGear?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="drivenWheel"
                name="drivenWheel"
                label="Driven Wheel"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "Front wheel" },
                  { id: 2, title: "Rear wheel" },
                  { id: 3, title: "all wheel" },
                  { id: 4, title: "4wheel" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("noOfSeat")}
                margin="normal"
                type="number"
                fullWidth
                id="noOfSeat"
                label="No of Seat"
                autoComplete="noOfSeat"
                autoFocus
                error={!!errors?.noOfSeat?.message}
                helperText={errors?.noOfSeat?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="hasSpareKey"
                name="hasSpareKey"
                label="Spare Key"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "yes" },
                  { id: 2, title: "no" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("noOfOwners")}
                margin="normal"
                fullWidth
                type="number"
                id="noOfOwners"
                label="No of Pervious Owner"
                autoComplete="noOfOwners"
                autoFocus
                error={!!errors?.noOfOwners?.message}
                helperText={errors?.noOfOwners?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="hasBooklet"
                name="hasBooklet"
                label="Service Booklet"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "yes" },
                  { id: 2, title: "no" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("noSpareKey")}
                margin="normal"
                type="number"
                fullWidth
                id="noSpareKey"
                label="Number of SpareKey"
                autoComplete="noSpareKey"
                autoFocus
                error={!!errors?.noSpareKey?.message}
                helperText={errors?.noSpareKey?.message}
                sx={{ m: 0 }}
              />
            </div> */}
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="principleWarranty"
                name="principleWarranty"
                label="Principal Warranty"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "yes" },
                  { id: 2, title: "no" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>{" "}
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="extendedWarranty"
                name="extendedWarranty"
                label="Extended Warranty"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "yes" },
                  { id: 2, title: "no" },
                ].map((item: { id: number; title: string }) => {
                  return (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <InputLabel sx={{ fontSize: "10px" }}>
                Registration Date
              </InputLabel>
              <Controller
                control={control}
                name="registrationDate"
                render={({ field: { onChange, value } }: any) => {
                  return (
                    <DatePicker
                      placeholderText="Registration Date"
                      onChange={(date) => onChange(date)}
                      selected={value ? value : ""}
                      className="date-picker-registration"
                      popperClassName="date-picker-registration-poper"
                      wrapperClassName="date-picker-registration-wrapper"
                    />
                  );
                }}
              />
            </div>
          </div>

          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};
