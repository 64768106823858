import { useAppSelector } from "../../../../utility/redux/state/hooks";
import { useForm } from "react-hook-form";
import { Card } from "../../shared/card";
import "./styles.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useLazyGetQueryQuery,
  usePatchRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SelectFormHook } from "../../shared/SelectFormHook";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import { ShopSearchT } from "../NewSellRequestModal/types";
import { managementType } from "../requests-filter";
import { Grade } from "../../../pages/inspection-report/components/grade";

interface Inputs {
  status: string;
  management?: string;
}

interface IProps {
  requestId: number;
  trackingCode: string;
  status: string;
  createdAt: string;
  sellRequestData: sellRequestDataT;
}

export function GeneralDetails(props: IProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [searchShopBy, setSearchShopBy] = useState<any>("pic");
  const [selectedShop, setIsSelectedShop] = useState<string>("");
  const [shopId, setShopId] = useState<string>("");

  const dispatch = useDispatch();
  const [patchData, result] = usePatchRequestMutation();

  const availableOptions = useAppSelector(
    (state) => state.sellRequestReducer.availableOptions
  );

  const [trigger, data] = useLazyGetQueryQuery();
  const shopSearchResult: ShopSearchT = data.data;

  const onSearchInputHandler = (text: string) => {
    if (text.length && text.length > 1) {
      const queryParams = `${searchShopBy}=${text}`;
      trigger(`${REST_STAFF_ENDPOINTS.shop}?${queryParams}`);
    }
  };

  useEffect(() => {
    const currentShop = selectedShop.split(" ");

    setShopId(currentShop[0]);
  }, [selectedShop]);

  useEffect(() => {
    reset({
      status: props.status,
      management: props.sellRequestData.managementType,
    });
  }, [reset, props.status]);

  const onHandleSubmit = (data: any) => {
    const payload: any = {
      status: data.status,
      requestId: props.requestId,
      management: data.management,
    };

    if (shopId) {
      payload.shopId = +shopId;
    }
    patchData({
      url: `${REST_STAFF_ENDPOINTS.general}`,
      payload,
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully car sell requested is submitted",
            type: "success",
          })
        );
      }
    });
  };
  return (
    <div className="car-detail-container">
      <Card header="General">
        <Box component="form" onSubmit={handleSubmit(onHandleSubmit)}>
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                margin="normal"
                fullWidth
                id="CreationTime"
                label="Creation Time"
                autoComplete="CreationTime"
                autoFocus
                value={
                  moment(props.createdAt).format("hh:mm A DD MMM YYYY") || ""
                }
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                margin="normal"
                fullWidth
                id="Tracking Code"
                label="Tracking Code"
                autoComplete="Tracking Code"
                autoFocus
                value={props.trackingCode || ""}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2 pt-2">
              <SelectFormHook
                helperText="Please select your currency"
                id="status"
                name="status"
                label="Status"
                control={control}
                defaultValue={""}
                required
              >
                {availableOptions &&
                  availableOptions.statuses.map((st) => {
                    return (
                      <MenuItem key={st} value={st}>
                        {st.toUpperCase()}
                      </MenuItem>
                    );
                  })}
              </SelectFormHook>
            </div>

            <Box
              sx={{
                display: "flex",
                p: 2,
                gap: 6,
              }}
            >
              <Box sx={{ minWidth: 100 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Search By
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchShopBy}
                    label="Search By"
                    onChange={(item) => setSearchShopBy(item.target.value)}
                  >
                    <MenuItem value={"pic"}>By PIC</MenuItem>
                    <MenuItem value={"email"}>By Email</MenuItem>
                    <MenuItem value={"name"}>By Name</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {props.sellRequestData.shop?.id ? (
                <Autocomplete
                  defaultValue={`${props.sellRequestData.shop?.id} ,${props.sellRequestData?.shop?.name},${props.sellRequestData.shop?.pic?.name} `}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    shopSearchResult &&
                    shopSearchResult.shops &&
                    shopSearchResult.shops.length
                      ? shopSearchResult.shops.map((option) => {
                          return `${option.id} ,${option.name} ,Managed By ${option.pic.name} `;
                        })
                      : []
                  }
                  sx={{ width: 300 }}
                  onChange={(_event, value) => {
                    value && setIsSelectedShop(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Shop"
                      onChange={(event) =>
                        onSearchInputHandler(event.target.value)
                      }
                    />
                  )}
                />
              ) : null}
            </Box>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                id="management"
                name="management"
                label="Management"
                control={control}
                defaultValue={""}
                required
              >
                {managementType.map(
                  (item: { name: string; value: string }, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.name.toString().toUpperCase()}
                      </MenuItem>
                    );
                  }
                )}
              </SelectFormHook>
            </div>

            <Box sx={{ maxWidth: 320 }}>
              <Typography> Grade</Typography>
              <Grade
                gradeNumber={
                  props.sellRequestData?.grade
                    ? +props.sellRequestData.grade
                    : 0
                }
              />
            </Box>
          </Box>

          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
}
