import { useForm } from "react-hook-form";

import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useEffect, useState } from "react";
import {
  useGetQueryQuery,
  usePostRequestMutation,
} from "../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { basicsT } from "../sell-request-details/types";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../utility/redux/state/features/auth.slicer";
import { Card } from "../../components/shared/card";
import { Box, Container } from "@mui/material";
import { AutoCompleteFormHook } from "../../components/shared/AutoCompleteFormHook";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Loading from "../../components/General/loading/loading";

interface Inputs {
  brandId: { id: number; title: string };
  modelId?: { id: number; title: string };
  language?: { title: string };
}

export const MetaData: FC<unknown> = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const dispatch = useDispatch();

  const [post, result] = usePostRequestMutation();

  const brand = watch("brandId");
  const model = watch("modelId");
  const language = watch("language");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlMeta, setHtmlMeta] = useState("");
  const rawContentState = convertToRaw(editorState.getCurrentContent());

  useEffect(() => {
    const markup = draftToHtml(rawContentState);
    setHtmlMeta(markup);
  }, [rawContentState]);

  const { data: basic_data } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}`
  );

  const { data: basicModels } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basicsSearch}/models?parentId=${brand?.id}`,
    {
      skip: !brand,
    }
  );

  const basicData: basicsT = basic_data;

  let url = `basics/static-data/seo/metadata-${brand?.title?.toLowerCase()}${
    model?.title?.toLowerCase() ? "-" + model?.title?.toLowerCase() : ""
  }${language?.title === "ms" ? "-ms" : model?.title ? "" : "-"}`;

  const {
    data: firstDraft,
    isSuccess,
    isLoading,
    refetch,
  } = useGetQueryQuery(
    url,

    {
      skip: !brand,
    }
  );
  useEffect(() => {
    if (firstDraft?.result?.[0] && isSuccess) {
      const length = firstDraft?.result?.length;

      const blocksFromHtml = htmlToDraft(
        firstDraft?.result?.[length - 1]?.text
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [firstDraft]);

  const onSubmit = async (data: Inputs) => {
    const carInfo = {
      brandId: data.brandId?.title?.toLowerCase(),
      modelId: data.modelId?.title?.toLowerCase(),
      language: data.language?.title,
    };

    const dataToSend = {
      payload: {
        text: htmlMeta,
      },
      name: `metadata-${carInfo.brandId}-`,
      subCategory: `metadata-${carInfo.brandId}-`,
    };

    if (carInfo.modelId) {
      dataToSend.name = `metadata-${carInfo.brandId}-${carInfo.modelId}`;
      dataToSend.subCategory = `metadata-${carInfo.brandId}-${carInfo.modelId}`;
    }

    if (carInfo.language === "ms") {
      if (carInfo.modelId) {
        dataToSend.name = `metadata-${carInfo.brandId}-${carInfo.modelId}-ms`;
        dataToSend.subCategory = `metadata-${carInfo.brandId}-${carInfo.modelId}-ms`;
      } else {
        dataToSend.name = `metadata-${carInfo.brandId}-ms`;
        dataToSend.subCategory = `metadata-${carInfo.brandId}-ms`;
      }
    }

    const result: any = await post({
      url: "/basics/internal/static-data/seo",
      payload: dataToSend,
    });

    if ("error" in result && result.error && result.error.data) {
      dispatch(
        setToaster({
          isOpen: true,
          title: result.error?.data?.message || "Submission Failed",
          type: "error",
        })
      );
    } else {
      refetch();
      dispatch(
        setToaster({
          isOpen: true,
          title: "Successfully MetaData  is submitted",
          type: "success",
        })
      );
    }

    if ("error" in result) {
      result.error &&
        dispatch(
          setToaster({
            isOpen: true,
            title: "Something went wrong",
            type: "error",
          })
        );
    }
  };
  if (isLoading) {
    return <Loading />;
  }

  const onEditorStateChange: any = (editorState: any) => {
    setEditorState(editorState);
  };

  const onEditorChange: any = (editorState: any) => {
    // setEditorState(editorState);
    // console.log("as HTML:", draftToHtml(editorState));
    // console.log(editorState, "editorState");
  };
  if (!basicData) {
    return <p>..Loading</p>;
  }

  return (
    <Container sx={{ mt: 12 }}>
      <Card header="Car MetaData">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <AutoCompleteFormHook
                options={basicData?.brands ?? []}
                disabled={false}
                name="brandId"
                label="Brands"
                control={control}
                defaultValue={""}
              />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <AutoCompleteFormHook
                options={basicModels?.models ?? []}
                disabled={false}
                name="modelId"
                label="Models"
                control={control}
                defaultValue={""}
              />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <AutoCompleteFormHook
                options={[{ title: "ms" }, { title: "en" }]}
                disabled={false}
                name="language"
                label="Language"
                control={control}
                defaultValue={""}
              />
            </div>
          </div>

          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right", marginTop: "10px" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>

      {brand?.id && !isLoading ? (
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          onChange={onEditorChange}
        />
      ) : null}
    </Container>
  );
};
