import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { TableDataT } from "../../sell-request-details/types";

import { AddPhotoAlternate } from "@mui/icons-material";
import {
  useDeleteRequestMutation,
  usePutRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import LoadingButton from "@mui/lab/LoadingButton";
import { ModalCoper } from "./modal-croper";
import DeleteIcon from "@mui/icons-material/Delete";
import { MultipleModalCrop } from "./MultipleCrop";
import Dropzone from "react-dropzone";
interface Inputs {
  generalRemark: string;
  inspectedImage: any;
  status: string;
}

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  currentId: number;
  tableData: TableDataT[];
  onNextHandler: () => void;
  onBackHandler: () => void;
  report_id: string;
  sellRequestId: string;
  refetch: () => void;
  isLoading: boolean;
}

export const InspectionModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  currentId,
  tableData,
  onNextHandler,
  onBackHandler,
  report_id,
  refetch,
  isLoading,
}) => {
  const [deleteImage] = useDeleteRequestMutation();
  const [putData, result] = usePutRequestMutation();
  const dispatch = useDispatch();
  const [selectedInspectionImage, setSelectedInspectionImage] = useState<any[]>(
    []
  );
  const [selectedTableData, setSelectedTableData] = useState<TableDataT>();
  const [valueStatus, setValueStatus] = useState("not-visited");
  const [valueGeneralRemark, setValueGeneralRemark] = useState<string>("");

  const [image, setImage] = useState();

  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const deleteLocalState = (index: number) => {
    const newArray = [...selectedInspectionImage];
    newArray.splice(index, 1);
    setSelectedInspectionImage(newArray);
  };

  const onDeleteImage = (index: number) => {
    const selectedImage = selectedInspectionImage[index];

    if (selectedImage.url) {
      deleteImage({
        url: `/internal/sell-requests/operations/images/insepection-detail/${selectedImage.id}`,
      }).then(() => {
        refetch();
        deleteLocalState(index);
      });
    } else {
      deleteLocalState(index);
    }
  };

  useEffect(() => {
    const currentData = tableData.filter(
      (item) => item.fakeId === currentId
    )[0];

    setSelectedTableData(currentData);
  }, [currentId, isOpen]);

  useEffect(() => {
    if (selectedTableData?.status) {
      setValueStatus(selectedTableData?.status);
    } else {
      setValueStatus("not-visited");
    }

    if (selectedTableData?.images && selectedTableData.images.length) {
      setSelectedInspectionImage(selectedTableData?.images);
    } else {
      setSelectedInspectionImage([]);
    }

    if (selectedTableData?.remark) {
      setValueGeneralRemark(selectedTableData?.remark);
    } else {
      setValueGeneralRemark("");
    }
  }, [selectedTableData, isOpen]);

  const onSubmit = () => {
    const formData = new FormData();

    selectedInspectionImage.forEach((element) => {
      !element.url && formData.append("images", element);
    });
    formData.append("remark", valueGeneralRemark);
    formData.append("status", valueStatus);
    //@ts-ignore
    formData.append("id", selectedTableData.id);

    putData({
      url: `internal/inspection-reports/${report_id}/details`,
      payload: formData,
      formData: true,
    }).then((res: any) => {
      if ("error" in res && res.error && (res.error.data || res.error.error)) {
        dispatch(
          setToaster({
            isOpen: true,
            title:
              res.error?.data?.message ||
              res.error.error ||
              "Submission Failed",
            type: "error",
          })
        );
      } else {
        refetch();
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully Updated",
            type: "success",
          })
        );
      }
    });
  };
  const handleChange = (event: any) => {
    setValueStatus(event.target.value);
  };

  const fileSelectedHandler = (e: any) => {
    setImage(e[0]);
    setIsCropModalOpen(true);
  };

  const handleAfterCrop = async (image: any) => {
    let currentImages = selectedInspectionImage.length
      ? [...selectedInspectionImage]
      : [];
    currentImages.push(image);
    setSelectedInspectionImage(currentImages);
    setIsCropModalOpen(false);
  };

  if (!selectedTableData) {
    return <p>...Loading</p>;
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => onCloseModal()}
      aria-labelledby="alert-dialog-title"
    >
      {image && (
        <MultipleModalCrop
          image={image}
          isOpen={isCropModalOpen}
          uploadImage={(image) => handleAfterCrop(image)}
          onClose={() => setIsCropModalOpen(false)}
        />
      )}

      <Box sx={{ minHeight: 360, p: 4 }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {" "}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography>
              Category: <strong>{selectedTableData.catagory}</strong>
            </Typography>
            <Typography>
              Sub Category: <strong>{selectedTableData.subCatagory}</strong>
            </Typography>
            <Typography>
              Title: <strong>{selectedTableData.title}</strong>
            </Typography>
          </Box>
          <LoadingButton
            sx={{ float: "right", mt: 4, width: 200, height: 40 }}
            loading={result.isLoading || isLoading}
            onClick={onSubmit}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>

        <Box mt={4}>
          <Typography variant="h6" fontWeight={"bold"}>
            Status
          </Typography>
          <Box display={"flex"} gap={4}>
            {["pass", "fail", "n/a"].map((item, index) => {
              return (
                <FormGroup key={index}>
                  <RadioGroup
                    aria-label={item}
                    name={item}
                    value={valueStatus}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      control={<Radio value={item} />}
                      label={
                        <Typography variant="subtitle1">
                          {item.toUpperCase()}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormGroup>
              );
            })}
          </Box>
        </Box>
        <TextField
          margin="normal"
          fullWidth
          label="Remark"
          sx={{ mt: 6 }}
          multiline
          rows={4}
          value={valueGeneralRemark}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setValueGeneralRemark(event.target.value)}
        />
        <Box p={2} display={"flex"} justifyContent={"space-between"}>
          <Button onClick={onBackHandler} variant="contained">
            Back
          </Button>
          <Button onClick={onNextHandler} color="success" variant="contained">
            Next
          </Button>
        </Box>
        <Dropzone onDrop={fileSelectedHandler}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <Box
                {...getRootProps()}
                sx={{
                  width: 400,
                  height: 140,

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  my: 2,
                }}
              >
                <input
                  id="upload-image-inspection"
                  type="file"
                  style={{ display: "none", width: "100%" }}
                  accept="image/png, image/gif, image/jpeg , image/jpg,image/heic"
                  // onChange={(event: any) => fileSelectedHandler(event.target.files[0])}
                  {...getInputProps()}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <AddPhotoAlternate
                    style={{ cursor: "copy" }}
                    fontSize="large"
                    sx={{
                      width: "60px",
                      height: "60px",
                      color: "gray",
                    }}
                  />{" "}
                  <Typography>Drag & Drop</Typography>
                </Box>
              </Box>
            </section>
          )}
        </Dropzone>
        <label
          htmlFor={`upload-image-inspection`}
          style={{ paddingTop: "10px" }}
        >
          <Typography
            color={"#1976d2"}
            sx={{ cursor: "copy" }}
            variant="caption"
            fontWeight={"bold"}
          >
            {selectedInspectionImage.length >= 1
              ? "Add More Photos"
              : "Add Photo"}
          </Typography>
        </label>
        <Box display={"flex"} flexDirection={"column"}>
          {selectedInspectionImage.length ? (
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {selectedInspectionImage.map((image, index) => {
                return (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={image.url ? image.url : URL.createObjectURL(image)}
                      width={200}
                      height={200}
                      loading="lazy"
                      style={{ objectFit: "cover" }}
                    />

                    <IconButton onClick={() => onDeleteImage(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <AddPhotoAlternate
              style={{ cursor: "copy" }}
              fontSize="large"
              sx={{
                width: "60px",
                height: "60px",
                color: "gray",
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
