import { Card } from "../../shared/card";
import "./styles.scss";

import { usePatchRequestMutation } from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { BuyRequestCarT } from "../../../pages/buy-request-details/types";

import React from "react";

const statusData = ["fee-paid", "pending", "contacted", "loan-approved", "loan-rejected"];
interface Inputs {
  status: string;
}

interface IProps {
  buyData: BuyRequestCarT;
  id?: string;
}

export function Remark(props: IProps) {
  const { buyData, id } = props;
  const dispatch = useDispatch();
  const [patchData, result] = usePatchRequestMutation();
  const [status, setStatus] = React.useState(buyData.status);
  const [remark, setRemark] = React.useState(buyData.remark);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const onRemarkSaveHandler = () => {
    patchData({
      url: `${REST_STAFF_ENDPOINTS.buyRequest}/${id}/remark`,
      payload: { remark: remark },
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully is updated",
            type: "success",
          })
        );
      }
    });
  };

  return (
    <div className="car-detail-container">
      <Card header="Remark">
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          <TextField
            margin="normal"
            fullWidth
            id="remark"
            label="Remark"
            value={remark}
            autoComplete="remark"
            autoFocus
            InputLabelProps={{ shrink: true }}
            sx={{ m: 0 }}
            multiline
            rows={3}
            onChange={(e) => setRemark(e.target.value)}
          />
          <Button sx={{ mt: 2, height: "40px" }} variant="contained" onClick={onRemarkSaveHandler}>
            Save
          </Button>
        </Box>
      </Card>
    </div>
  );
}
