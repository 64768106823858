export const REST_STAFF_ENDPOINTS = {
  login: "internal/staff/login",
  sellRequest: "internal/sell-requests",
  sellRequestOps: "internal/sell-requests/operations",
  basics: "basics",
  basicsSearch: "basics/search",
  basicsLocations: "basics/locations",
  inspectionReport: "/internal/inspection-reports",
  buyRequest: "/internal/buy-requests",
  shop: "/internal/shops",
  general: "/internal/sell-requests/operations/general",
 
};
