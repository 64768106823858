import { Box, TextField } from "@mui/material";
import { IInputsType } from "./types";
export interface ITextBoxProps extends IInputsType {
  type?: "text" | "password" | "date";
  disabled?: boolean;
  value?: any;
  onKeyPress?: any;
  fullWidth?: boolean;
  bigFont?: boolean;
  width?: number;
}
export function TextBox({
  label,
  value,
  help,
  register,
  onKeyPress,
  required = false,
  type = "text",
  fullWidth,
  disabled,
  bigFont,
  width,
}: ITextBoxProps) {
  return (
    <Box sx={{ minWidth: 160, width: width ? width : "auto" }}>
      <TextField
        autoComplete="off"
        fullWidth={fullWidth}
        onKeyDown={onKeyPress}
        {...(register != null ? { ...register } : {})}
        required={required}
        value={value}
        type={type}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: bigFont ? 20 : "initial" },
        }}
        InputProps={{
          readOnly: disabled,
          style: { fontSize: bigFont ? 20 : "initial" },
          autoComplete: "off",
        }}
        id="standard-basic"
        label={label}
        variant="outlined"
        helperText={help}
      />
    </Box>
  );
}
