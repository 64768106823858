import { LocationOn, Store } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";

import { FC } from "react";

interface Props {
  shopName?: string;
  shopAddress?: string;
}

export const ShopDetail: FC<Props> = ({ shopName, shopAddress }) => {
  return (
    <Box sx={{ display: "flex", gap: 3, pb: 3 }}>
      <TextField
        margin="normal"
        id="Shop Name"
        value={shopName}
        label={"Shop Name"}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Store />
            </InputAdornment>
          ),
        }}
        sx={{ m: 0 }}
      />
      {shopAddress ? (
        <TextField
          margin="normal"
          id="address"
          value={shopAddress}
          label={"Address Shop"}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
          sx={{ m: 0 }}
        />
      ) : null}
    </Box>
  );
};
