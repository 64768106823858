import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Logout,
  DirectionsCar,
  CarRepair,
  Dashboard,
  Summarize,
  DataArray,
} from "@mui/icons-material";

import "./styles.scss";
import { useDispatch } from "react-redux";
import { logOut } from "../../../utility/redux/state/features/auth.slicer";
import { Box, Typography } from "@mui/material";

export const sidePanelItems = [
  {
    text: "Dashboard",
    link: "/dashboard",
    icon: <Dashboard />,
  },
  {
    text: "Sell Requests",
    link: "/sell-requests",
    icon: <DirectionsCar />,
  },
  {
    text: "Buy Requests",
    link: "/buy-requests",
    icon: <CarRepair />,
  },

  {
    text: "Report",
    link: "/report",
    icon: <Summarize />,
  },

  {
    text: "Meta Data",
    link: "/metadata",
    icon: <DataArray />,
  },

  {
    text: "Sign Out",
    link: "/login",
    icon: <Logout />,
    action: "logout",
  },
];

interface Props {
  isSidePanelHide: boolean;
  isLastItem?: boolean;
}

const SidePanel: React.FC<Props> = ({ isSidePanelHide }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token") || localStorage.getItem("token") == "") {
      navigate("/login");
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      {" "}
      <Box sx={{ display: isSidePanelHide ? "none " : "auto" }}>
        <Box sx={{ gap: 2, p: 3 }} className="side-bar-panel">
          {sidePanelItems.map((item, index) => (
            <SidePanelItem item={item} key={index} isLastItem={index === 5} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default SidePanel;

function SidePanelItem({
  item,
  isLastItem,
}: {
  item: any;
  isLastItem: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNavHandler = (link: string, action?: string) => {
    if (action === "logout") {
      window.localStorage.removeItem("token");
      dispatch(logOut());
      navigate(item.link);
    } else {
      navigate(item.link);
    }
  };

  return (
    <Box
      className="side-bar-panel-item"
      onClick={() => {
        onNavHandler(item.link, item.action);
      }}
    >
      <Typography sx={{ color: isLastItem ? "Red" : "black" }}>
        {item.icon} {item.text}
      </Typography>
    </Box>
  );
}
