import { Box, Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { InspectionDefault } from "./components/inspection-default";
import { InspectionGeneral } from "./components/inspection-general";
import { InspectionOverallCarPhoto } from "./components/inspection-overal-car-photo";
import {
  useGetQueryQuery,
  usePostRequestMutation,
} from "../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { basicsT, sellRequestDataT } from "../sell-request-details/types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InspectionCar } from "./components/inspection-car";
import { Modal } from "../../components/General/Modal";
import { Toaster } from "../../components/General/Toaster";
import { useAppSelector } from "../../../utility/redux/state/hooks";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../utility/redux/state/features/auth.slicer";

export const InspectionReport: FC<unknown> = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postCompleted] = usePostRequestMutation();
  const toaster = useAppSelector((state) => state.authReducer.toaster);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenCompletedModal, setIsOpenCompletedModal] = useState(false);
  const [currentTab, setCUrrentTab] = useState(0);

  const reportId = searchParams.get("report_id");
  const current = searchParams.get("currentTab");

  const { data: basic_data } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}`
  );

  const { data: { request: sellerRequest } = {}, refetch } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.sellRequest}/${id}`,
    {
      skip: !id || id === "",
    }
  );

  const sellRequestData: sellRequestDataT = sellerRequest;

  const basicData: basicsT = basic_data;
  const handleComplete = () => {
    refetch();
    setIsOpenCompletedModal(true);
  };
  const tabChanged = ({
    prevIndex,
    nextIndex,
  }: {
    prevIndex: number;
    nextIndex: number;
  }) => {    
    setCUrrentTab(nextIndex);
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      (currentTab || currentTab === 0) &&
        searchParams.set("currentTab", currentTab.toString());
      return searchParams;
    });

    if(currentTab && currentTab == 3){
      window.scrollTo(0, 0);
    }

  }, [currentTab]);

  const onCompleteHandler = () => {
    postCompleted({
      url: `${REST_STAFF_ENDPOINTS.inspectionReport}/${reportId}/complete`,
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully car sell requested is submitted",
            type: "success",
          })
        );
        refetch();
        navigate(`/sell-requests/${id}`);
      }
    });
  };

  return (
    <Box>
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <Modal
        title="completed"
        onCLose={() => setIsOpenCompletedModal(false)}
        isOpen={isOpenCompletedModal}
        description="Car details is completed successfully"
        onConfirmHandler={onCompleteHandler}
      />{" "}
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          sx={{ mt: 10 }}
          variant="contained"
          onClick={() => navigate(`/sell-requests/${id}`)}
          tabIndex={-1}
        >
          Back to Sell request Details
        </Button>
      </Box>
      <FormWizard
        onComplete={handleComplete}
        onTabChange={tabChanged}
        startIndex={current ? +current - 1 : currentTab}
      >
        <FormWizard.TabContent title="Overall" icon="ti-user">
          {basicData && sellRequestData && (
            <InspectionDefault
              basicData={basicData}
              sellRequestData={sellRequestData}
            />
          )}
        </FormWizard.TabContent>
        <FormWizard.TabContent
          title="General Inspection Info"
          icon="ti-settings"
        >
          {id && reportId && sellRequestData && (
            <InspectionGeneral sellRequestId={id} reportId={reportId} />
          )}
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Overall Car Photos" icon="ti-image">
          {id && (
            <InspectionOverallCarPhoto
              requestId={id}
              refetch={refetch}
              sellRequestData={sellRequestData}
            />
          )}
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Inspection Report" icon="ti-car">
          {sellRequestData?.inspections[0]?.id && id && (
            <InspectionCar
              report_id={sellRequestData.inspections[0].id.toString()}
              sellRequestId={id}
            />
          )}
        </FormWizard.TabContent>
      </FormWizard>
      <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
    </Box>
  );
};
