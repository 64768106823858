import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Card } from "../../shared/card";
import "./styles.scss";
import { usePatchRequestMutation } from "../../../../utility/redux/api/api-slice";
import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import { RULES } from "../car-details/rules";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";

interface Inputs {
  remark: string;
  appointments: {
    type: string;
    times: [
      {
        timestamp: string;
        duration: number;
      }
    ];
  };
}
interface Props {
  requestId: number;
  sellRequestData: sellRequestDataT;
  refetch: () => void;
}

export const CustomerAdvisor: React.FC<Props> = ({
  requestId,
  sellRequestData,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const [patchData, putDataResult] = usePatchRequestMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data: any) => {
    const timestamp = dayjs(data.appointments.times[0].timestamp).unix() * 1000;
    const payload = {
      requestId: requestId,
      remark: data.remark,
      appointments: {
        type: "inspection",
        times: [
          {
            timestamp,
            duration: data.appointments.times[0].duration,
          },
        ],
      },
    };
    const result: any = await patchData({
      url: "internal/sell-requests/operations/customer-advisor-detail",
      payload,
    });
    if ("error" in result && result.error && result.error.data) {
      dispatch(
        setToaster({
          isOpen: true,
          title: result.error?.data?.message || "Submission Failed",
          type: "error",
        })
      );
    } else {
      refetch();
      dispatch(
        setToaster({
          isOpen: true,
          title: "Successfully car sell requested is submitted",
          type: "success",
        })
      );
    }
  };

  useEffect(() => {
    reset({
      remark: sellRequestData.generalRemark,
      appointments: {
        type: "Inspection",
        times: [
          {
            timestamp: sellRequestData.appointment.timestamp
              ? (new Date(+sellRequestData.appointment.timestamp) as any)
              : "",
            duration: sellRequestData.appointment.duration
              ? sellRequestData.appointment.duration
              : 2,
          },
        ],
      },
    });
  }, [reset]);

  const handleColor = (time: Date) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  return (
    <div className="seller-detail-container">
      <Card header="Customer Advisor">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-1">
              <InputLabel>Duration</InputLabel>
              <TextField
                {...register(
                  "appointments.times.0.duration",
                  RULES.COMMON_RULES_STRING
                )}
                margin="normal"
                fullWidth
                id="duration"
                label={null}
                autoComplete="duration"
                autoFocus
                required
                InputLabelProps={{ shrink: true }}
                error={!!errors?.appointments?.times?.[0]?.duration?.message}
                helperText={errors.appointments?.times?.[0]?.duration?.message}
                sx={{ m: 0 }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4">
              <InputLabel>Appointments Date</InputLabel>

              <Controller
                control={control}
                name="appointments.times.0.timestamp"
                render={({ field: { onChange, value } }: any) => {
                  return (
                    <DatePicker
                      placeholderText="Select date"
                      onChange={(date) => onChange(date)}
                      selected={value ? value : ""}
                      timeClassName={handleColor}
                      showTimeSelect
                      className="date-picker"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  );
                }}
              />
            </div>
          </div>

          <Box sx={{ mb: 2, pt: 2 }}>
            <TextField
              {...register("remark")}
              margin="normal"
              fullWidth
              id="remark"
              label="Remark"
              autoComplete="remark"
              autoFocus
              InputLabelProps={{ shrink: true }}
              error={!!errors?.remark?.message}
              helperText={errors.remark?.message}
              sx={{ m: 0 }}
              multiline
              rows={3}
            />
          </Box>
          <LoadingButton
            loading={putDataResult.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};
