import {
  Box,
  Card as CardComponent,
  CardContent,
  Typography,
} from "@mui/material";
import { Button } from "../button";

export function Card({
  children,
  header,
  minWidth = "18rem",
  submitBtnText,
  submitBtnHandler,
}: {
  children: any;
  header?: string;
  minWidth?: string;
  submitBtnText?: string;
  submitBtnHandler?: any;
}) {
  const HeaderCard = () => {
    if (submitBtnText && submitBtnText !== "") {
      return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{header}</Typography>
          <Button
            label={submitBtnText}
            onClick={submitBtnHandler}
            type="submit"
          />
        </Box>
      );
    } else {
      return (
        <Typography fontWeight={"bold"} pb={1} variant="subtitle1">
          {header}
        </Typography>
      );
    }
  };

  return (
    <Box sx={{ minWidth: minWidth }}>
      <CardComponent variant="outlined" sx={{ p: 2 }}>
        <Box>
          <HeaderCard />
        </Box>

        <CardContent>{children} </CardContent>
      </CardComponent>
    </Box>
  );
}
