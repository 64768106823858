const COMMON_RULES_STRING = {
  required: "Required Filed",
};

const COMMON_RULES_NUMBER = {
  required: "Required Filed",
};

const EMAIL = {
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Invalid Email Address",
  },
};

export const RULES = {
  COMMON_RULES_STRING,
  EMAIL,
};
