import React from "react";
import { Box } from "@mui/material";

const Dashboard: React.FC = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={12}
    >
      <h1 style={{ fontWeight: "bold" }}>Dashboard</h1>
      <h3>Welcome to META CAR admin panel</h3>
      <Box mt={14}>
        <div className="logo-container">
          <img
            src="https://metacar.my/images/metacar-logo.png"
            className="logo"
            style={{ objectFit: "contain" }}
          />
        </div>
      </Box>
    </Box>
  );
};
export default Dashboard;
