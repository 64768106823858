import React from "react";
import "./styles.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { PageHeader } from "../../components/shared/page-header";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Toaster } from "../../components/General/Toaster";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";

import { BuyRequestT } from "./types";
import Loading from "../../components/General/loading/loading";
import { GeneralDetailsBuyRequest } from "../../components/sell-requests/general-details-buy-req/general-details";
import { CarInfo } from "../../components/sell-requests/general-details-buy-req/car-info";
import { BuyerInfo } from "../../components/sell-requests/general-details-buy-req/buyer-info";
import { Remark } from "../../components/sell-requests/general-details-buy-req/remark";
import { GeneralPhotosBuyRequest } from "../../components/sell-requests/general-details-buy-req/general-photos/general-photo";

export const BuyRequestDetails: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let { id } = useParams();

  const { data: buyRequestData = {}, refetch } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.buyRequest}/${id}`,
    {
      skip: !id || id === "",
    }
  );

  const { buyRequest, promotion }: BuyRequestT = buyRequestData;

  const toaster = useAppSelector((state) => state.authReducer.toaster);

  if (!buyRequest) {
    return <Loading />;
  }

  return (
    <div className="sell-request-list-container">
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <PageHeader label="Buy Request Details" />
      <GeneralDetailsBuyRequest
        buyData={buyRequest}
        id={id}
        promotion={promotion}
      />
      <CarInfo buyData={buyRequest} id={id} />
      <BuyerInfo buyData={buyRequest} id={id} />
      <Remark buyData={buyRequest} id={id} />
      <GeneralPhotosBuyRequest
        buyData={buyRequest}
        requestId={id}
        refetch={refetch}
      />
    </div>
  );
};
