import { Box, Typography } from "@mui/material";
import { FC } from "react";
import StarRatings from "react-star-ratings";

interface Props {
  gradeNumber: number;
}

export const Grade: FC<Props> = ({ gradeNumber }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        gap: 1,
      }}
    >
      {gradeNumber && gradeNumber > 0 && (
        <>
          <Typography variant="h5">{gradeNumber}</Typography>
          <StarRatings
            rating={gradeNumber}
            starRatedColor="orange"
            numberOfStars={5}
            name="rating"
            starDimension={"30px"}
          />
        </>
      )}
    </Box>
  );
};
