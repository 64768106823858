import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";

import DeleteIcon from "@mui/icons-material/Delete";
import Pdf from "@mui/icons-material/PictureAsPdf";
import { useDispatch } from "react-redux";
import {
  useDeleteRequestMutation,
  usePostRequestMutation,
} from "../../../../../utility/redux/api/api-slice";
import { setToaster } from "../../../../../utility/redux/state/features/auth.slicer";
import { Card } from "../../../shared/card";
import { BuyRequestCarT } from "../../../../pages/buy-request-details/types";

interface Props {
  requestId?: string;
  buyData: BuyRequestCarT;
  refetch: () => void;
}

export const GeneralPhotosBuyRequest: React.FC<Props> = ({
  buyData,
  refetch,
  requestId,
}) => {
  const dispatch = useDispatch();
  const [post, result] = usePostRequestMutation();
  const [deleteImage] = useDeleteRequestMutation();

  const [selectedGeneralImages, setSelectedGeneralImages] = useState<any[]>([]);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState();

  const deleteLocalState = (index: number) => {
    const newArray = [...selectedGeneralImages];
    newArray.splice(index, 1);
    setSelectedGeneralImages(newArray);
  };

  const onDeleteImage = (index: number) => {
    deleteImage({
      url: `/internal/buy-requests/${requestId}/documents/${index}`,
    }).then((res: any) => {
      if ("error" in res && res.error && (res.error.data || res.error.error)) {
        dispatch(
          setToaster({
            isOpen: true,
            title:
              res.error?.data?.message ||
              res.error.error ||
              "Submission Failed",
            type: "error",
          })
        );
      } else {
        refetch();
        deleteLocalState(index);
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully Updated",
            type: "success",
          })
        );
      }
    });
  };

  const fileSelectedHandler = (e: any) => {
    const currentImages = [];
    currentImages.push(e);
    setSelectedGeneralImages(currentImages);
    setCurrentImage(e);
    setIsCropModalOpen(true);
  };

  useEffect(() => {
    currentImage && onSubmit();
  }, [currentImage]);

  const onSubmit = () => {
    const formData = new FormData();
    selectedGeneralImages.forEach((element) => {
      !element.url && formData.append("file", element);
    });

    post({
      url: `internal/buy-requests/${requestId}/documents`,
      payload: formData,
      formData: true,
    }).then((res: any) => {
      if ("error" in res && res.error && (res.error.data || res.error.error)) {
        dispatch(
          setToaster({
            isOpen: true,
            title:
              res.error?.data?.message ||
              res.error.error ||
              "Submission Failed",
            type: "error",
          })
        );
      } else {
        refetch();
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully Updated",
            type: "success",
          })
        );
      }
    });
  };

  useEffect(() => {
    if (buyData?.documents && buyData?.documents.length) {
      setSelectedGeneralImages(buyData?.documents);
    } else {
      setSelectedGeneralImages([]);
    }
  }, []);

  return (
    <div className="seller-detail-container">
      <Card header="General Car Documents">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            p={2}
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              {selectedGeneralImages.length ? (
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  {buyData.documents.map((image, index) => {
                    return (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {image?.url?.toLowerCase()?.includes(".pdf") ? (
                          <a href={image.url} target="_blank">
                            <Box
                              sx={{
                                width: 200,
                                height: 200,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: "18px",
                                justifyContent: "center",
                                p: 4,
                                gap: 1,
                                border: "1px solid gray",
                              }}
                            >
                              <Typography>{image.note}</Typography>
                              <Pdf sx={{ fontSize: 80 }} />
                            </Box>
                          </a>
                        ) : (
                          <a href={image.url} target="_blank">
                            <img
                              src={image.url}
                              width={200}
                              height={200}
                              loading="lazy"
                              style={{ objectFit: "cover" }}
                            />
                          </a>
                        )}

                        <IconButton onClick={() => onDeleteImage(image.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <AddPhotoAlternate
                  style={{ cursor: "copy" }}
                  fontSize="large"
                  sx={{
                    width: "60px",
                    height: "60px",
                    color: "gray",
                  }}
                />
              )}
              <input
                id="upload-image-sell-general"
                type="file"
                style={{ display: "none", width: "100%" }}
                accept="application/pdf, image/png, image/gif, image/jpeg , image/jpg,image/heic"
                onChange={(event: any) =>
                  fileSelectedHandler(event.target.files[0])
                }
              />
            </Box>
          </Box>

          <Button
            sx={{ float: "right", height: 40, padding: 0 }}
            // onClick={() => navigate(`/sell-requests/${buyData.car.carId}`)}
            variant="contained"
          >
            {" "}
            <label
              style={{
                height: "100%",
                paddingTop: "8px",
                paddingLeft: "6px",
                paddingRight: "6px",
                cursor: "pointer",
              }}
              htmlFor={`upload-image-sell-general`}
            >
              Upload
            </label>
          </Button>
        </Box>
      </Card>
    </div>
  );
};
