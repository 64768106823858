import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
// import handleToast from "../../helpers/toast/handleToast";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      //TODO
      // if (
      //   action.payload.data.code === "auth/id-token-expired" ||
      //   action.payload.data.code === "auth/argument-error"
      // ) {
      //   localStorage.removeItem("token");
      // }
    }

    return next(action);
  };
