import { PropsWithChildren, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SidePanel from "../side-panel/side-panel";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { Menu } from "@mui/icons-material";
const Layout = ({ children }: PropsWithChildren) => {
  const [isSidePanelHide, setSidePanelHide] = useState(false);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: "aliceblue" }}>
          <Toolbar>
            <IconButton
              onClick={() => setSidePanelHide(!isSidePanelHide)}
              size="large"
              edge="start"
              color="success"
              aria-label="menu"
              sx={{ mr: 2, color: "black" }}
            >
              <Menu fontSize={"large"} />
            </IconButton>
            <div className="logo-container">
              <img
                width={200}
                src="https://metacar.my/images/metacar-logo.png"
                className="logo"
              />
            </div>
          </Toolbar>
        </AppBar>
      </Box>

      <div className="layout-container">
        <Box
          sx={{
            position: "fixed",
            width: isSidePanelHide ? 0 : 280,
            height: 400,
            top: 70,
          }}
        >
          <SidePanel isSidePanelHide={isSidePanelHide} />
        </Box>

        <Box
          sx={{
            marginLeft: isSidePanelHide ? 0 : "280px",
            marginTop: "62px",
            width: "100%",
            position: "relative",

            overflow: "auto",
            zIndex: 2,
          }}
          className="page-container"
        >
          {children}
        </Box>
      </div>
    </div>
  );
};
export default Layout;
