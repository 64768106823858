import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { IInputsType } from "./types";
import { Controller } from "react-hook-form";

export interface IDropDownProps extends IInputsType {
  items: { id: number | string; title: string }[];
  defaultValue?: string;
  onChangeHandler?: any;
  basedInId?: boolean;
  disabled?: boolean;
  control?: any;
  name: string;
}

export function DropDown(props: IDropDownProps) {
  const {
    label,
    items,
    help,
    register,
    defaultValue,
    onChangeHandler,
    basedInId,
    control,
    disabled,
    name,
  } = props;

  if (control) {
    return (
      <Box sx={{ minWidth: 160 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>

          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Select
                disabled={disabled}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={defaultValue}
                label={label}
                {...(register != null ? { ...register } : {})}
                // onChange={onChangeHandler}
                {...field}
              >
                <MenuItem value=""> ALL </MenuItem>
                {items.map((itm, index) => (
                  <MenuItem
                    key={index}
                    selected={itm.title == defaultValue}
                    value={basedInId ? itm.id : itm.title}
                  >
                    {itm.title}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <FormHelperText> {help}</FormHelperText>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box sx={{ minWidth: 160 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>

        <Select
          disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={defaultValue}
          label={label}
          {...(register != null ? { ...register } : {})}
          onChange={onChangeHandler}
        >
          <MenuItem value=""> ALL </MenuItem>
          {items.map((itm, index) => (
            <MenuItem
              key={index}
              selected={itm.title == defaultValue}
              value={basedInId ? itm.id : itm.title}
            >
              {itm.title}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText> {help}</FormHelperText>
      </FormControl>
    </Box>
  );
}
