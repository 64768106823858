import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddPhotoAlternate } from "@mui/icons-material";
import { FC, useState } from "react";
import {
  useDeleteRequestMutation,
  useGetQueryQuery,
  usePostRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { ImageListT, sellRequestDataT } from "../../sell-request-details/types";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import "cropperjs/dist/cropper.css";
import { ModalCoper } from "./modal-croper";
import { resizeFile } from "./umage-resizer";
import Dropzone from "react-dropzone";

interface Props {
  requestId: string;
  refetch: () => void;
  sellRequestData: sellRequestDataT;
}
export const InspectionOverallCarPhoto: FC<Props> = ({
  requestId,
  refetch,
  sellRequestData,
}) => {
  const { data: images, refetch: refetchImages } =
    useGetQueryQuery(`basics/images/all`);

  const [deleteImage] = useDeleteRequestMutation();

  const [postData, result] = usePostRequestMutation();
  const dispatch = useDispatch();

  const [image, setImage] = useState();
  const [subIdImage, setSubId] = useState<undefined | number>();
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const handleDrop = (acceptedFiles: any, subId: number) => {
    setSubId(subId);
    setImage(acceptedFiles[0]);
    setIsCropModalOpen(true);
  };

  if (!images) {
    return <p>...Loading</p>;
  }
  const carImages: ImageListT[] = images.list;

  const onSubmit = async (data: any) => {
    if (subIdImage) {
      const formData = new FormData();
      formData.append("requestId", requestId);
      formData.append("subCategoryId", subIdImage.toString());
      formData.append("file", data);
      postData({
        url: `internal/sell-requests/operations/images`,
        payload: formData,
        formData: true,
      }).then((res: any) => {
        if (
          "error" in res &&
          res.error &&
          (res.error.data || res.error.error)
        ) {
          dispatch(
            setToaster({
              isOpen: true,
              title:
                res.error?.data?.message ||
                res.error.error ||
                "Submission Failed",
              type: "error",
            })
          );
        } else {
          refetch();
          dispatch(
            setToaster({
              isOpen: true,
              title: "Successfully car sell requested is submitted",
              type: "success",
            })
          );
        }
      });
    }
  };

  const onDeleteImage = (id: number) => {
    deleteImage({
      url: `/internal/sell-requests/operations/images/${id}`,
    }).then(() => refetch());
  };

  const handleBeforeCrop = (data: any, subId: number) => {
    setSubId(subId);
    setImage(data.target.files[0]);
    setIsCropModalOpen(true);
  };

  const handleAfterCrop = async (image: any) => {
    setIsCropModalOpen(false);
    const resultResizeImage = await resizeFile(image);
    onSubmit(resultResizeImage);
  };

  return (
    <Container sx={{ mb: 10 }}>
      {image && (
        <ModalCoper
          image={image}
          isOpen={isCropModalOpen}
          uploadImage={(image) => handleAfterCrop(image)}
          onClose={() => setIsCropModalOpen(false)}
        />
      )}

      <Box
        display={"flex"}
        gap={4}
        width={"100%"}
        flexDirection={"column"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        {carImages
          .slice(0)
          .reverse()
          .map((image, index) => {
            return (
              <Box key={index} flexGrow={1} width={"100%"}>
                <Typography variant="h6" textAlign={"left"} pb={2}>
                  {image.subCategories && image.subCategories.length
                    ? image.title.toUpperCase()
                    : null}
                </Typography>
                <Box display={"flex"} gap={6} flexWrap={"wrap"}>
                  {image.subCategories.map((sub, index) => {
                    const hasImage = !!sellRequestData?.images?.filter(
                      (image) => image.subCategory === sub.title
                    )[0]?.image;
                    const subId = sub.id;

                    return (
                      <Box
                        sx={{
                          position: "relative",
                          minHeight: 200,
                          width: { xs: "30%", md: "14%" },
                        }}
                      >
                        <Box>
                          <Dropzone onDrop={(file) => handleDrop(file, subId)}>
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <Box
                                  {...getRootProps()}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                  }}
                                  tabIndex={-1}
                                >
                                  <input {...getInputProps()} id={"subId"} />
                                </Box>
                              </>
                            )}
                          </Dropzone>
                        </Box>

                        <Box key={index} pt={1} className="FilesDragAndDrop">
                          <label htmlFor={`upload-image-${subId}`}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <Typography
                                height={{ xs: 60, md: 52, lg: 30 }}
                                variant="caption"
                                textAlign={"center"}
                              >
                                {index + 1}. {sub.title.toUpperCase()}
                              </Typography>

                              {hasImage ? (
                                <Box
                                  sx={{
                                    cursor: "zoom-in",
                                    "&:hover": {
                                      transition: ".2s",
                                      transform: " scale(4.8)",
                                      zIndex: 10,
                                    },
                                  }}
                                >
                                  <img
                                    width={100}
                                    height={100}
                                    style={{ objectFit: "contain" }}
                                    src={
                                      sellRequestData?.images.filter(
                                        (image) =>
                                          image.subCategory === sub.title
                                      )[0]?.image
                                    }
                                  />{" "}
                                </Box>
                              ) : (
                                <AddPhotoAlternate
                                  style={{ cursor: "copy" }}
                                  fontSize="large"
                                  sx={{
                                    width: "100px",
                                    height: "100px",
                                    color: "gray",
                                  }}
                                />
                              )}
                              {result.isLoading && subIdImage === sub.id ? (
                                <CircularProgress size={20} />
                              ) : hasImage ? (
                                <IconButton
                                  tabIndex={-1}
                                  onClick={() =>
                                    onDeleteImage(
                                      sellRequestData?.images?.filter(
                                        (image) =>
                                          image.subCategory === sub.title
                                      )[0]?.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </label>

                          <input
                            id={`upload-image-${subId}`}
                            style={{ display: "none" }}
                            type="file"
                            accept="image/png, image/gif, image/jpeg , image/jpg, image/heic"
                            onChange={(event) => {
                              handleBeforeCrop(event, subId);
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Container>
  );
};
