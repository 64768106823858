//@ts-ignore
import convertFromHeic from "heic-convert/browser";

export const heicConvertor = async (file: File, blob?: boolean) => {
  const inputBuffer = new Uint8Array(await file.arrayBuffer());

  const outputBuffer = await convertFromHeic({
    buffer: inputBuffer, // the HEIC file buffer
    format: "PNG", // output format
  });

  const result = new Blob([outputBuffer], { type: "image/png" });
  if (blob) {
    return result;
  } else {
    return URL.createObjectURL(result);
  }
};

// export const heicConvertor = async (file: File, blob?: boolean) => {
//   try {
//     if (typeof window !== "undefined") {
//       const heic2any = require("heic2any");
//       const output = await heic2any({
//         blob: file,
//         toType: "image/jpeg",
//         quality: 0.7,
//       });
//       // Check if the output is a single Blob or an array of Blobs
//       const outputBlob = Array.isArray(output) ? output[0] : output; // Assuming we use the first Blob if it's an array

//       // Create a new File object from the Blob
//       const newName = file.name.replace(/\.(heic|heif)$/i, "") + ".jpg";

//       return new File([outputBlob], newName, {
//         type: "image/jpeg",
//       });
//     }
//   } catch (error) {
//     console.error("Error converting HEIC/HEIF file:", error);
//     return file; // Return the original file in case of an error
//   }
