import { useForm } from "react-hook-form";
import { Card } from "../../shared/card";

import {
  useGetQueryQuery,
  usePatchRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { useEffect, useState } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SelectFormHook } from "../../shared/SelectFormHook";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import {
  Loan,
  LoanBackendApi,
  LoanResponse,
} from "../../../pages/sell-request-details/types";
import dayjs from "dayjs";

interface IProps {
  requestId: number;
  currency: string;
  finalPrice: string;
  proposedPrice: string;
  installmentAmount: string;
  isBelowMarketPrice: boolean;
  refetch: () => void;
  loanInfo: Loan;
}

interface Inputs {
  currency: string;
  proposedPrice: string;
  finalPrice: string;
  installmentAmount?: string;
  isBelowMarketPrice?: string;
  estLoanAmount: number;
  loanType: number | null;
}

export function FinancialDetails(props: IProps) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const [patchData, result] = usePatchRequestMutation();
  const dispatch = useDispatch();

  const [selectedLoan, setSelectedLoan] = useState<LoanBackendApi>();

  const { requestId } = props;

  const { data: loanRequest, refetch } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.sellRequest}/${requestId}/loans`,
    {
      skip: !requestId,
    }
  );

  const loanRequestData: LoanResponse = loanRequest;

  useEffect(() => {
    if (loanRequestData?.loans?.length) {
      const selectedLoan = loanRequestData.loans.filter(
        (item) => item.id === watch("loanType")
      );

      if (selectedLoan?.length) {
        setValue("estLoanAmount", selectedLoan[0].estimatedLoanAmount);
        setSelectedLoan(selectedLoan[0]);
      } else {
        setValue("estLoanAmount", 0);
        setSelectedLoan({
          id: null,
          estimatedLoanAmount: 0,
        });
      }
    }
  }, [watch("loanType")]);

  useEffect(() => {
    if (loanRequestData?.loans.length && props?.loanInfo?.id) {
      const initialLoan = loanRequestData.loans.filter((item) => {
        return item.id === props?.loanInfo?.id;
      });
      if (initialLoan?.length) {
        setValue("estLoanAmount", initialLoan[0].estimatedLoanAmount);
        setSelectedLoan(initialLoan[0]);
      }
    }
  }, [props.loanInfo, loanRequestData]);

  useEffect(() => {
    reset({
      currency: props.currency ? props.currency : "rm",
      proposedPrice: props.proposedPrice
        ? parseFloat(props.proposedPrice).toFixed(2)
        : "",
      finalPrice: props.finalPrice
        ? parseFloat(props.finalPrice).toFixed(2)
        : "",
      installmentAmount:
        props.loanInfo.id === null || 0
          ? "0.00"
          : props.installmentAmount
          ? parseFloat(props.installmentAmount).toFixed(2)
          : "",
      isBelowMarketPrice: props.isBelowMarketPrice ? "yes" : "no",
      loanType: props.loanInfo.id === null ? 0 : props.loanInfo.id,
    });
  }, [props.installmentAmount, reset, props.requestId]);

  const onHandleSubmit = (data: Inputs) => {
    const payload = {
      isBelowMarketPrice: data.isBelowMarketPrice === "yes" ? true : false,
      requestId: props.requestId,
      currency: data.currency,
      proposedPrice: {
        amount: parseFloat(data.proposedPrice.replace(/,/g, "")),
      },
      finalizedPrice: {
        amount: parseFloat(data.finalPrice.replace(/,/g, "")),
      },
      loan: {
        id: data.loanType === 0 ? null : data.loanType,
        monthlyInstallment:
          data.loanType === 0 || null ? 0 : watch("estLoanAmount"),
      },
    };

    patchData({
      url: `${REST_STAFF_ENDPOINTS.sellRequestOps}/financial-info`,
      payload,
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully car sell requested is submitted",
            type: "success",
          })
        );
        props.refetch();
      }
    });
  };
  return (
    <div className="car-detail-container">
      <Card header="Financial Details">
        <Box component="form" onSubmit={handleSubmit(onHandleSubmit)}>
          <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                helperText="Please select your currency"
                id="currency"
                name="currency"
                label="Currency"
                control={control}
                defaultValue={""}
              >
                {[{ id: 1, title: "rm" }].map((st) => {
                  return (
                    <MenuItem key={st.id} value={st.title}>
                      {st.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <SelectFormHook
                helperText="Below Market Price"
                id="isBelowMarketPrice"
                name="isBelowMarketPrice"
                label="Below Market Price"
                control={control}
                defaultValue={""}
              >
                {[
                  { id: 1, title: "yes" },
                  { id: 2, title: "no" },
                ].map((st) => {
                  return (
                    <MenuItem key={st.id} value={st.title}>
                      {st.title.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </SelectFormHook>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("proposedPrice")}
                margin="normal"
                fullWidth
                id="proposedPrice"
                label="Proposed Price"
                autoComplete="proposedPrice"
                autoFocus
                error={!!errors?.proposedPrice?.message}
                helperText={errors?.proposedPrice?.message}
                InputLabelProps={{ shrink: true }}
                sx={{ m: 0 }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("finalPrice")}
                margin="normal"
                fullWidth
                id="finalPrice"
                label="Final Price"
                autoComplete="finalPrice"
                autoFocus
                error={!!errors?.finalPrice?.message}
                helperText={errors?.finalPrice?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2">
              <TextField
                {...register("installmentAmount")}
                margin="normal"
                fullWidth
                id="installmentAmount"
                label="Installment Amount"
                autoComplete="installmentAmount"
                autoFocus
                error={!!errors?.installmentAmount?.message}
                helperText={errors?.installmentAmount?.message}
                sx={{ m: 0 }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-sm-6 col-md-4 pt-4">
              <SelectFormHook
                showHelperText={!!selectedLoan}
                id="loan"
                name="loanType"
                label="Select Loan"
                control={control}
                defaultValue={""}
              >
                {" "}
                <MenuItem value={-1}>General</MenuItem>
                <MenuItem value={0}>Cash Only</MenuItem>
                {loanRequestData?.loans?.length ? (
                  loanRequestData?.loans.map((st) => {
                    if (st.id) {
                      return (
                        <MenuItem key={st.id} value={st.id}>
                          Loan Id:{st.id} - Year:{st.maxManufactureYear}
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <MenuItem disabled value="">
                    No options available
                  </MenuItem>
                )}
              </SelectFormHook>

              {selectedLoan && selectedLoan.id !== null ? (
                <Box
                  p={1}
                  mt={1}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.06)",
                    borderRadius: 4,

                    minWidth: 420,
                  }}
                >
                  <Typography py={1}>
                    <strong> Selected Loan Info</strong>
                  </Typography>{" "}
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    gap={2}
                    sx={{}}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      {" "}
                      <Typography>
                        Interest Rate:
                        <strong> {selectedLoan?.interestRate}</strong>
                      </Typography>
                      <Typography>
                        Max Loan Year:
                        <strong> {selectedLoan?.maxLoanYear}</strong>
                      </Typography>
                      <Typography>
                        Updated At:
                        <strong>
                          {" "}
                          {dayjs(selectedLoan?.updatedAt).format("YYYY/MM/DD")}
                        </strong>
                      </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography>
                        Max Manufacture Year:
                        <strong> {selectedLoan?.maxManufactureYear}</strong>
                      </Typography>
                      <Typography>
                        Max Tenure Year:
                        <strong> {selectedLoan?.maxTenureYear}</strong>
                      </Typography>
                      <Typography>
                        Min Tenure Year:
                        <strong> {selectedLoan?.minTenureYear}</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </div>
            <div className="col-sm-6 col-md-3 col-lg-2 pt-4">
              <TextField
                {...register("estLoanAmount")}
                margin="normal"
                fullWidth
                id="estLoanAmount"
                label="Estimated Loan Amount"
                autoComplete="estLoanAmount"
                autoFocus
                error={!!errors?.estLoanAmount?.message}
                helperText={errors?.estLoanAmount?.message}
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </div>{" "}
          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
}
