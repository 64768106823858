import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FC, createRef, useEffect, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { heicConvertor } from "./heic-convertor";
import { resizeFile } from "./umage-resizer";

interface Props {
  image: any;
  isOpen: boolean;
  uploadImage: (image: Blob) => void;
  onClose: () => void;
}
export const ModalCoper: FC<Props> = ({
  image,
  isOpen,
  uploadImage,
  onClose,
}) => {
  const cropperRef = createRef<ReactCropperElement>();

  const [imageToShow, setImageToShow] = useState<string>();
  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const file = await fetch(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      )
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "inspection.png", { type: blob.type });
        });

      uploadImage(file);
    }
  };

  const onConversion = async (file: File) => {
    if (file.type === "image/heic" || file.type === "image/heif") {
      //@ts-ignore
      setImageToShow(await heicConvertor(image));
    } else {
      setImageToShow(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    image && onConversion(image);
  }, [image]);

  return (
    <Dialog maxWidth="lg" open={isOpen} onClose={onClose}>
      <DialogContent>
        {imageToShow && (
          <Cropper
            ref={cropperRef}
            style={{ height: 600, width: "100%" }}
            zoomTo={0}
            initialAspectRatio={1}
            preview=".img-preview"
            src={imageToShow}
            viewMode={1}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            background={false}
            responsive={false}
            aspectRatio={16 / 9}
            autoCropArea={1}
            checkOrientation={true}
            guides={true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={getCropData}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
